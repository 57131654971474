<script setup>
import { computed } from "vue";
import { Link, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    users: Object
});
</script>

<template>
    <Dashboard page_title="Listagem de membros">
        <Breadcrumb currentPageName="Listagem de membros" />
        <PageNavigationHeader pageTitle="Membros" baseRoute="users" :showAdd="permissions.create_user" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <div class="table-responsive-sm col-11 mx-auto p-3">
                        <table class="table table-hover" id="tableAuthors">
                            <thead>
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Email</th>
                                    <th class="text-center" scope="col">Função</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in users" :key="index">
                                    <th class="text-center">{{ parseInt(index) + 1 }}</th>
                                    <td>
                                        <img class="rounded-circle me-2" width="25" :src="user.avatar" alt="">
                                        {{ user.name }}
                                    </td>
                                    <td>{{ user.email }}</td>
                                    <td class="text-center"><span class="badge bg-warning text-dark p-2">{{
                                        user.role }}</span></td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/users/${user.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_user" :href="`/dashboard/users/edit/${user.id}`"
                                            class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

