<script setup>
import { onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3'

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import Lear from './Components/Lear.vue';

const props = defineProps({
    session: Object,
    allCategories: Object,
    sessionCategories: Object
});

const form = useForm({
    name: props.session.name,
    visible: props.session.visible,
    description: props.session.description,
    rhema: props.session.rhema,
    categories: []
});

const update = () => form.put(`/dashboard/store-session/${props.session.id}`);

onMounted(() => {
    form.categories = props.sessionCategories.map((category) => category.id);
});
</script>

<template>
    <Dashboard :page_title="session.name">
        <div class="row mx-0" id="edit-author">
            <Breadcrumb :currentPageName="session.name" :linkList="[
                { previous_page_url: 'store-session', previous_page_name: 'Seções' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-md-10 col-12 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados da seção</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon :href="`/dashboard/store-session/${session.id}`" background="primary"
                                color="white" icon="eye" message="Visualizar" additionalStyles="me-2" />
                        </div>
                    </div>
                    <hr>
                    <form class="shadow-none mt-4" id="author-data-editing" @submit.prevent="update">
                        <div class="row g-3 mb-3">
                            <div class="col-md-5">
                                <label for="name" class="form-label">Nome da seção da loja</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name"
                                    v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>

                            <div class="col-md-3 col-6">
                                <label for="rhema" class="form-label">Rhema</label>
                                <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="visible" v-model="form.visible">
                                    <label class="form-check-label" for="visible">
                                        Seção visível
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição</label>
                                <textarea type="text" class="form-control" id="description" name="description"
                                    v-model="form.description" rows="5"></textarea>
                            </div>

                            <div class="col-md-12">
                                <h5>Categorias vinculadas</h5>
                                <ul class="list-group overflow-auto category-listing mt-3">
                                    <li class="list-group-item p-3" v-for="(category, index) in allCategories" :key="index">
                                        <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                            :id="'checkBoxCategory' + category.id"
                                            :checked="form.categories.includes(category.id) ? true : false"
                                            v-model="form.categories">
                                        <label class="form-check-label stretched-link ms-3 fs-13"
                                            :for="'checkBoxCategory' + category.id" style="cursor: pointer;">{{
                                                category.name
                                            }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row d-flex justify-content-between mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#section-lear" role="button" aria-controls="section-lear">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success  float-end" type="submit"
                                    :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Atualizar 
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

