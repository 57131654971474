<script setup>
import { reactive, onMounted, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    books: Object
});

const searchForm = reactive({
    name: null,
    isbn: null,
    in_promotion: '',
    stock_available: '',
    created_at: null
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
}

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('name')) {
        searchForm.name = urlParams.get('name');
    }
    if (urlParams.has('isbn')) {
        searchForm.isbn = urlParams.get('isbn');
    }
    if (urlParams.has('in_promotion')) {
        searchForm.in_promotion = urlParams.get('in_promotion');
    }
    if (urlParams.has('stock_available')) {
        searchForm.stock_available = urlParams.get('stock_available');
    }
    if (urlParams.has('created_at')) {
        searchForm.created_at = urlParams.get('created_at');
    }
});

const { convertToCurrencyFormat } = usePriceFormatter();

const seacher = () => router.get('/dashboard/books', searchForm, { preserveState: true });
</script>

<template>
<Dashboard page_title="Listagem de livros">
    <Breadcrumb currentPageName="Listagem de livros" />
    <PageNavigationHeader pageTitle="Livros" baseRoute="books" :showAdd="permissions.create_book" />
    <div class="row mt-4 mx-0">
        <div class="bg-white rounded-3 border">
            <div class="row">
                <form action="" class="col-11 mx-auto p-3">
                    <div class="d-flex justify-content-between align-items-center mt-2 row">
                        <div class="font-bold col-12 col-sm-6">
                            Baixar estoque de livros
                        </div>
                        <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">

                            <ButtonWithIcon :href="`/dashboard/books/pdf/export`" background="danger" color="white"
                                icon="file-pdf" :linkInertia="false" message="PDF" additionalStyles="me-2" />

                            <ButtonWithIcon :href="`/dashboard/books/xlxs/export`" background="success" color="white"
                                icon="file-excel" :linkInertia="false" message="Excel" />
                        </div>
                    </div>
                    <hr>
                    <div class="row g-3">
                        <div class="col-lg-8">
                            <label for="name" class="form-label">Nome do livro</label>
                            <input type="text" @input="compositionSeacher('name', $event)" id="name"
                                class="form-control" autocomplete="on" v-model="searchForm.name"
                                placeholder="LADRÕES DE SONHOS" name="">
                        </div>
                        <div class="col-lg-4">
                            <label for="isbn" class="form-label">ISBN</label>
                            <input type="text" id="isbn" class="form-control" autocomplete="on"
                                placeholder="978-85-6621-722-3" @input="compositionSeacher('isbn', $event)"
                                v-model="searchForm.isbn" name="">
                        </div>
                        <div class="col-sm-4">
                            <label for="isbn" class="form-label">Em promoção</label>
                            <label for="in_promotion" class="form-label"></label>
                            <select class="form-select" @change="seacher()" id="in_promotion" v-model="searchForm.in_promotion">
                                <option selected value="">Todos status</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <label for="stock_available" class="form-label">Estoque</label>
                            <label for="stock_available" class="form-label"></label>
                            <select class="form-select" @change="seacher()" id="stock_available"
                                v-model="searchForm.stock_available">
                                <option selected value="">Todos status</option>
                                <option value="1">Disponível</option>
                                <option value="0">Em falta</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <label for="createdAt" class="form-label">Data de criação</label>
                            <input type="date" @change="seacher()" id="createdAt" class="form-control" v-model="searchForm.created_at"
                                name="">
                        </div>
                    </div>
                </form>

                <hr class="col-11 mx-auto">

                <div class="table-responsive col-11 mx-auto">
                    <table class="table table-hover" id="table-books">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th class="text-center" scope="col">Preço</th>
                                <th class="text-center" scope="col">ISBN</th>
                                <th class="text-center" scope="col">Quantidade</th>
                                <th class="text-center">Ações</th>
                            </tr>
                        </thead>

                        <tbody v-if="books.meta.total > 0">
                            <tr v-for="(book, index) in books.data" :key="index"
                                :class="book.current_promotion ? 'bg-warning bg-opacity-10' : ''">
                                <td scope="row">{{ parseInt(index) + 1 }}</td>
                                <td>{{ book.name }}</td>
                                <td class="text-center">{{
        book.current_promotion ?
            convertToCurrencyFormat(book.current_promotion.discounted_price) :
            convertToCurrencyFormat(book.original_price)
    }}</td>
                                <td class="text-center">{{ book.isbn }}</td>
                                <td class="text-center">{{ book.quantity >= 1 ? book.quantity : '-' }}</td>
                                <td class="text-center">
                                    <Link :href="`/dashboard/books/${book.id}`" class="btn btn-success">
                                    <font-awesome-icon :icon="['fas', 'eye']" />
                                    </Link>
                                    <Link v-if="permissions.edit_book" :href="`/dashboard/books/edit/${book.id}`"
                                        class="btn btn-primary ms-2">
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                        <tr class="text-center" v-else>
                            <td colspan="6" class="p-3 font-semibold fs-14">
                                Nenhum livro encontrado
                            </td>
                        </tr>
                        <tfoot>
                            <tr class="border border-white" v-if="books.meta.total > 0">
                                <td class="text-end" colspan="6">
                                    <span class="fs-14 font-semibold">{{ books.meta.total }} livros
                                        encontrados
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <Pagination :data="books.meta" reference_date="books" />
                </div>
            </div>
        </div>
    </div>
</Dashboard>
</template>
