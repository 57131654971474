<template>
<div class="container-fluid p-3 mt-4 mb-5 depositions">
    <div class="row mx-auto my-auto">
        <h2 class="text-center mb-4 title-with-arrows">DEPOIMENTOS</h2>
        <div :id="id" class="carousel carousel-dark slide col-lg-11 mx-auto" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div v-for="(group, index) in groupedDepositions" :key="index"
                    :class="['carousel-item', { active: index === 0 }]">
                    <div class="row g-4 p-2">
                        <div class="col" v-for="(deposition, indice) in group" :key="indice">
                            <div class="card">
                                <div class="card-body">
                                    <img class="rounded-circle mx-auto d-block border border-5 border-warning"
                                        :src="deposition.photo" :alt="deposition.name">
                                    <h5 class="text-center font-bold mt-3 fs-14">{{ deposition.name }}</h5>
                                    <p class="card-text p-4">{{ deposition.deposition }}</p>
                                </div>
                                <div class="card-footer bg-white text-center office border-0 mb-4">
                                    {{ deposition.office }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-indicators mt-3">
                <button type="button" :data-bs-target="'#' + id" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" :data-bs-target="'#' + id" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>

        </div>
    </div>
</div>
</template>

<script setup>
import { ref, computed } from 'vue';

const depositions = ref([
    {
        name: 'Micheline Paiva',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'O livro “O Espírito da Fé” me ensinou a confessar o que eu creio e tornar meus sonhos impossíveis em realidade.',
        office: 'Diretora do Rhema'
    },
    {
        name: 'Valdeilson Paiva',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'O livro “Construídos para Durar” marcou minha vida, pois Deus deseja que nos relacionemos com Ele e com os outros de forma saudável.',
        office: 'Ministro do Rhema'
    },
    {
        name: 'Andrey Ferreira',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'De um jeito divertido e objetivo, este livro nos ensina a praticar a Palavra de Deus também na nossa vida on-line. Leitura indispensável para esta geração! “Deus Curte. Eu Compartilho.',
        office: 'Ministro do Rhema'
    },
    {
        name: 'Renato de Sá',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'O livro “Construídos para Durar” marcou minha vida, pois Deus deseja que nos relacionemos com Ele e com os outros de forma saudável.',
        office: 'Ministro do Rhema'
    },
    {
        name: 'Micheline Paiva',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'O livro “O Espírito da Fé” me ensinou a confessar o que eu creio e tornar meus sonhos impossíveis em realidade.',
        office: 'Diretora do Rhema'
    },
    {
        name: 'Andrey Ferreira',
        photo: 'https://cms.evup.com.br/api/assets/ecommerce/bdb5a2d1-4562-4ef0-861c-050b12954510/',
        deposition: 'De um jeito divertido e objetivo, este livro nos ensina a praticar a Palavra de Deus também na nossa vida on-line. Leitura indispensável para esta geração! “Deus Curte. Eu Compartilho.',
        office: 'Ministro do Rhema'
    },
]);

const id = ref('carouselExample');

// Function to group items
const groupArray = (arr, groupSize) => {
    const groups = [];
    for (let i = 0; i < arr.length; i += groupSize) {
        groups.push(arr.slice(i, i + groupSize));
    }
    return groups;
};

// Computed property to get grouped depositions
const groupedDepositions = computed(() => groupArray(depositions.value, 3));
</script>