<template>
    <span :class="`badge bg-${statusStyle.background} text-${statusStyle.color} p-2`">{{ formattedBadge }}</span>
  </template>
  
  <script setup>
  import { computed, defineProps } from 'vue';
  
  const props = defineProps({ badge: String });
  
  const statusStyle = computed(() => {
      const style = {
          pendente: {
              color: 'dark',
              background: 'warning'
          },
          aprovado: {
              color: 'white',
              background: 'primary'
          },
          cancelado: {
              color: 'white',
              background: 'danger'
          },
          cancelada: {
              color: 'white',
              background: 'danger'
          },
          finalizado: {
              color: 'white',
              background: 'success'
          },
          concretizada: {
              color: 'white',
              background: 'success'
          },
          vinculo_realizado: {
              color: 'white',
              background: 'primary'
          },
          livro_disponivel: {
              color: 'white',
              background: 'success'
          },
          livro_entregue: {
              color: 'white',
              background: 'success'
          },
          pastor: {
              color: 'white',
              background: 'primary'
          },
          aluno_rhema: {
              color: 'white',
              background: 'danger'
          },
          comum: {
              color: 'white',
              background: 'secondary'
          }
      };
      return style[props.badge.toLowerCase()];
  });
  
  const formattedBadge = computed(() => {
      return props.badge.replace(/_/g, ' ');
  });
  </script>
  