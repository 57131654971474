<script setup>
import { Link } from '@inertiajs/vue3';
import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();
const props = defineProps({
    books: Object,
    title: String
});
</script>

<template>
    <div class="col p-0" id="simplifiedBookListing">
        <div v-if="Object.values(books).length > 0">
            <h4 class="mt-4">{{ Object.values(books).length }} {{ title }}</h4>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xxl-4 g-3 mt-2">
                <div class="col" v-for="(book, index) in books" :key="index">
                    <Link class="text-decoration-none text-muted" :href="`/dashboard/books/${book.id}`">
                    <div class="card">
                        <div class="card-img mt-3 d-flex align-items-center justify-content-center">
                            <img :src="`/storage/${book.cover_image_url}`" class="" alt="">
                        </div>
                        <div class="card-body">
                            <div class="card-title text-center d-flex align-items-center justify-content-center">
                                <h5>{{ book.name }}</h5>
                            </div>

                            <p class="card-price text-center" v-if="!book.current_promotion">
                                {{ convertToCurrencyFormat(book.original_price) }}
                            </p>

                            <p v-else class="card-price text-center">
                                <span class="text-secondary text-decoration-line-through">{{
                                    convertToCurrencyFormat(book.original_price) }}
                                </span> -
                                <span class="text-success">
                                    {{ convertToCurrencyFormat(book.current_promotion.discounted_price) }}
                                </span>
                            </p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
        <div class="mt-4" v-else>
            <p class="border-start border-5 border-primary p-3 bg-info bg-opacity-10 font-medium fs-14">
                Até o momento, não há registro de nenhum livro associado a este item
            </p>
        </div>
    </div>
</template>

