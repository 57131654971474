<script setup>
import { onMounted, ref } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const props = defineProps({
    role: Object,
    allPermissions: Object,
    rolePermissions: Object,
    errors: Object
});

const formEditRole = useForm({
    display_name: props.role.display_name,
    description: props.role.description,
    permissions: []
});

const roleEditModalCloseButton = ref(null);

const update = () => router.put(`/dashboard/roles/${props.role.id}`, formEditRole);
const destroy = () => {
    roleEditModalCloseButton.value.click();
    router.delete(`/dashboard/roles/${props.permission.id}`);
};

onMounted(() => {
    formEditRole.permissions = props.rolePermissions.map((role) => role.id);
});
</script>

<template>
    <Dashboard :page_title="role.display_name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="role.display_name" :linkList="[
                { previous_page_url: 'roles', previous_page_name: 'Funções' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-6">
                        <h4 class="font-bold m-0">Editar dados da função</h4>
                    </div>
                    <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                        <ButtonWithIcon 
                            :href="`/dashboard/roles/${role.id}`" 
                            background="primary" 
                            color="white"
                            icon="eye" 
                            message="Visualizar" 
                            additionalStyles="me-2"
                        />
                    </div>
                </div>
                <hr>
                <form class="border-0 shadow-none" @submit.prevent="update">
                    <div class="row g-4">
                        <div class="col-4">
                            <label for="display_name" class="form-label">Nome legível da função</label>
                            <input type="text" id="display_name" class="form-control" v-model="formEditRole.display_name" autocomplete="off" required>
                            <small class="text-danger" v-if="errors.display_name">{{
                                errors.display_name
                            }}</small>
                        </div>
                        <div class="col-8">
                            <label for="description" class="form-label">Nome legível da função</label>
                            <input type="text" id="description" class="form-control" v-model="formEditRole.description" autocomplete="off" required>
                            <small class="text-danger" v-if="errors.description">{{
                                errors.description
                            }}</small>
                        </div>
                        
                        <ul class="list-group overflow-auto p-2 normal-listing">
                            <h5 class="mb-4 font-bold">Permissões atribuídas a esta função</h5>
                            <li class="list-group-item p-3" v-for="(permission, index) in allPermissions" :key="index">
                                <input class="form-check-input me-1" type="checkbox" :value="permission.id"
                                    :id="'checkBoxPermission' + permission.id"
                                    :checked="formEditRole.permissions.includes(permission.id) ? true : false"
                                    v-model="formEditRole.permissions">
                                <label class="form-check-label stretched-link ms-3"
                                    :for="'checkBoxPermission' + permission.id" style="cursor: pointer;">{{
                                        permission.display_name 
                                    }} - {{ permission.description }}</label>
                            </li>
                        </ul>
                    </div>
                    <button class="btn btn-success mt-3 float-end" type="submit">
                        Atualizar dados
                    </button>
                </form>

                <ConfirmationModal modal_id="roleDestroyModal" modal_title="Confirmação de remoção do cliente">
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir a função de {{ role.display_name }}?
                    </template>
                    <template v-slot:modal-footer>
                        <button type="button" ref="roleDestroyModalCloseButton" class="btn btn-secondary text-white"
                            data-bs-dismiss="modal">Voltar</button>
                        <button @click="destroy" type="button"
                            class="btn btn-danger text-white">Deletar função
                        </button>
                    </template>
                </ConfirmationModal>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

