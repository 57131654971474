<script setup>
import { reactive, onMounted, computed } from 'vue';
import { Link, router, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({ 
    categories: Object
});

const searchForm = reactive({ name: '' });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
}

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('name')) {
        searchForm.name = urlParams.get('name');
    }
});

const seacher = () => router.get('/dashboard/categories', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de categorias">
        <Breadcrumb currentPageName="Listagem de categorias" />
        <PageNavigationHeader pageTitle="Categorias" baseRoute="categories" :showAdd="permissions.create_category" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto py-3">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-3">
                                <label for="name" class="form-label">Nome da categoria</label>
                                <input type="text" autocomplete="on" id="name" class="form-control" v-model="searchForm.name" placeholder="Rhema"
                                    @input="compositionSeacher('name', $event)">
                            </div>
                        </div>
                    </form>

                    <hr class="col-11 mx-auto">
                    <div class="table-responsive-sm col-11 mx-auto">
                        <table class="table table-hover" id="table-categories">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="!!categories.meta.total > 0">
                                <tr v-for="(category, index) in categories.data" :key="index">
                                    <th scope="row">{{ parseInt(index) + 1 }}</th>
                                    <td>{{ category.name }}</td>
                                    <td class="text-center">{{ category.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/categories/${category.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_category" :href="`/dashboard/categories/edit/${category.id}`" class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhuma categoria encontrada
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="categories.meta.total > 0">
                                    <td class="text-end" colspan="6">
                                        <span class="fs-14 font-semibold">{{ categories.meta.total }} categorias
                                            encontradas
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="categories.meta" reference_date="categories" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

