<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    permission: Object
});
</script>

<template>
    <Dashboard :page_title="permission.display_name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="permission.display_name" :linkList="[
                { previous_page_url: 'permissions', previous_page_name: 'Permissões' }
            ]" />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">{{ permission.display_name }}</h4>
                        </div>
                        <div v-if="permissions.edit_permission" class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon
                                :href="`/dashboard/permissions/edit/${permission.id}`" 
                                background="primary" 
                                color="white"
                                icon="pen-to-square" 
                                message="Editar permissão"
                                additionalStyles="me-2"
                            />
                        </div>
                    </div>
                    <hr>
                    <p class="font-regular">
                        {{ permission.description }}
                    </p>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

