
<script setup>
import { usePage } from '@inertiajs/vue3';
import { onMounted, computed } from 'vue';
import { Tooltip } from 'bootstrap';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import SimplifiedBookListing from '@/Pages/Dashboard/Shared/SimplifiedBookListing.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    category: Object,
    books: Object
});

onMounted(() => {
    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
    })
});
</script>

<template>
    <Dashboard :page_title="category.name">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="category.name" :linkList="[
                { previous_page_url: 'categories', previous_page_name: 'Categorias' }
            ]" />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">{{ category.name }}</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end p-2">
                            <button 
                                type="button" 
                                class="btn btn-success me-2 m-0" 
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                :data-bs-title="`${category.visible_public ? 'Vísivel na loja' : 'Não vísivel na loja'}`">
                                <font-awesome-icon :icon="['fas', category.visible_public ? 'eye' : 'eye-slash']" />
                            </button>

                            <ButtonWithIcon v-if="permissions.edit_category"
                                :href="`/dashboard/categories/edit/${category.id}`" 
                                background="primary" 
                                color="white"
                                icon="pen-to-square" 
                                message="Editar categoria"
                            />
                        </div>
                    </div>
                    <div>
                        <img :src="category.photo ? `/storage/${category.photo}` : '/img/author-default-cover-photo.png'" class="mx-auto d-block profile-image border border-5 my-4 rounded-circle" alt="...">

                </div>
                    <template v-if="category.description">
                        <hr>
                        <p class="font-regular">
                            {{ category.description }}
                        </p>
                    </template>

                </div>
                <SimplifiedBookListing title="livros desta categoria para você explorar" :books="books" />
            </div>
        </div>
    </Dashboard>
</template>
