<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="favorites" aria-labelledby="favoritesLabel">
        <div class="offcanvas-header border-bottom">
            <h5 class="offcanvas-title font-bold" id="favoritesLabel">Livros favoritados</h5>
            <button type="button" class="btn-close" ref="offcanvas" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <div v-if="bookLength == 0">
                    <p class="font-regular">
                        Nenhum livro adicionado 
                    </p>
                </div>
                <div v-else class="col-6 col-sm-12 mb-4" v-for="(book, indice) in books" :key="indice">
                    <div class="card-book card-hover h-100 pe-auto border-0 p-3">
                        <div class="card-body">
                            <div class="col">
                            <button data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remover dos favoritos" class="btn btn-danger float-end mb-3" @click="removeFromFavoritesByCleanName(book.clean_name)">
                                <font-awesome-icon :icon="['fa', 'ban']" />
                            </button>
                        </div>
                        <div class="card-img mx-auto"><img :src="book.cover_image_url_link" class="mx-auto d-block loaded"
                                alt="" /></div>
                        <div class="card-body p-0 p-3">
                            <h5 class="card-title text-center mt-4 mt-sm-0">{{ book.name }}</h5>
                            <p class="card-text">
                            <div class="book-price d-flex justify-content-center align-items-center">
                                <span>{{ formatPrice(book.original_price) }}</span>
                            </div>
                            </p>

                            <div class="d-flex justify-content-end">
                                <Link class="btn btn-primary font-bold fs-13" :href="`/livros/${book.clean_name}`" @click="offcanvas.click()">
                                    Ver livro
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, onMounted } from 'vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const books = ref();
const bookLength = ref(0);
const offcanvas = ref();
const { convertToCurrencyFormat } = usePriceFormatter();

const favoriteBooks = () => {
    let alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    books.value = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];
    bookLength.value = books.value.length;
};

const removeFromFavoritesByCleanName = (clean_name) => {
    let alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    let convertToArray = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];

    const index = convertToArray.findIndex((favoriteBook) => favoriteBook.clean_name === clean_name);

    if (index !== -1) {
        convertToArray.splice(index, 1);
        localStorage.setItem('verboshop_favorites', JSON.stringify(convertToArray));
        favoriteBooks();
    }
};

const formatPrice = (value) => convertToCurrencyFormat(value);

onMounted(() => {
    const offcanvasElement = document.getElementById('favorites');

    offcanvasElement.addEventListener('shown.bs.offcanvas', favoriteBooks);
    offcanvasElement.addEventListener('hidden.bs.offcanvas', favoriteBooks);

    favoriteBooks();
});
</script>