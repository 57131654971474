<script setup>
import { onMounted, watchEffect, ref, computed } from "vue";
import { useForm, router, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import Lear from './Components/Lear.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

import { vMaska } from "maska";

const props = defineProps({
    promotion: Object,
    booksPromotion: Object,
    allBooks: Object,
    categories: Object
});

const form = useForm({
    name: props.promotion.name,
    discount_percentage: props.promotion.discount_percentage,
    active: props.promotion.active ? 1 : 0,
    rhema: props.promotion.rhema ? 1 : 0,
    books: [],
    categories: []
});

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const update = () => form.put(`/dashboard/promotions/${props.promotion.id}`);

const limitPercentage = () => {
    if (form.discount_percentage > 100) {
        form.discount_percentage = 100;
    }
}

const promotionEditModalCloseButton = ref(null);
const destroy = () => {
    promotionEditModalCloseButton.value.click();
    router.delete(`/dashboard/promotions/${props.promotion.id}`);
};

onMounted(() => {
    form.books = props.booksPromotion.map((book) => book.id);
});

const selectAllBooks = ref(false);

watchEffect(() => {
    if (selectAllBooks.value) {
        form.books = props.allBooks.map(book => book.id);
    } else {
        form.books = [];
    }
});
</script>

<template>
    <Dashboard :page_title="promotion.name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="promotion.name" :linkList="[
                { previous_page_url: 'promotions', previous_page_name: 'Promoções' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados da promoção</h4>
                        </div>

                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon :href="`/dashboard/promotions/${promotion.id}`" background="primary"
                                color="white" icon="eye" message="Visualizar" additionalStyles="me-2" />

                                <ButtonWithIcon  v-if="permissions.destroy_promotion"
                                href="#" 
                                background="danger" 
                                color="white"
                                icon="trash-can" 
                                :linkInertia="false" 
                                message="Deletar"
                                dataBsToggle="modal"
                                dataBsTarget="#promotionEditModal"
                            />
                        </div>
                    </div>
                    <hr>

                    <form class="border-0 shadow-none" @submit.prevent="update">
                        <div class="row g-3">
                            <div class="col-md-5">
                                <label for="name" class="form-label">Nome da promoção</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name"
                                    placeholder="Dia das mães" v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            
                            <div class="col-md-3 col-6">
                                <label for="rhema" class="form-label">Rhema</label>
                                <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <div class="col-md-2 col-6">
                                <label for="discount_percentage" class="form-label">Desconto</label>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1">%</span>
                                    <input type="text" autocomplete="off" class="form-control" id="discount_percentage"
                                        name="discount_percentage" v-model="form.discount_percentage" v-maska
                                        data-maska="##" required @keyup="limitPercentage()">
                                </div>
                                <small class="text-danger mt-2" v-if="form.errors.discount_percentage">{{
                                    form.errors.discount_percentage
                                }}</small>
                            </div>

                            <div class="col-md-2 col-6">
                                <label for="active" class="form-label">Ativa</label>
                                <select class="form-select" name="active" id="active" v-model="form.active">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <hr>

                            <h4>Livros vinculados</h4>

                            <ul class="list-group overflow-auto normal-listing p-2">
                                <li class="list-group-item p-3">
                                    <input class="form-check-input me-1" type="checkbox" :id="'selectAllBooks'" v-model="selectAllBooks">
                                    <label class="form-check-label stretched-link ms-3 fs-13" :for="'selectAllBooks'" style="cursor: pointer;">Selecionar todos os livros</label>
                                </li>
                                <li class="list-group-item p-3" v-for="(book, index) in allBooks" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="book.id"
                                        :id="'checkBoxBook' + book.id"
                                        :checked="form.books.includes(book.id) ? true : false" v-model="form.books">
                                    <label class="form-check-label stretched-link ms-3 fs-13"
                                        :for="'checkBoxBook' + book.id" style="cursor: pointer;">{{
                                            book.name
                                        }}</label>
                                </li>
                            </ul>

                            <h4>Categorias</h4>

                            <ul class="list-group overflow-auto normal-listing p-2">
                                <li class="list-group-item p-3" v-for="(category, index) in categories" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                        :id="'checkBoxCategory' + index" v-model="form.categories">
                                    <label class="form-check-label stretched-link ms-3 fs-13"
                                        :for="'checkBoxCategory' + index" style="cursor: pointer;">{{ category.name
                                        }}</label>
                                </li>
                            </ul>

                        </div>

                        <div class="row d-flex justify-content-between mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#promotion-lear" role="button" aria-controls="order-lear">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success mt-3 mt-sm-0" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Atualizar dados
                                </button>
                            </div>
                        </div>
                    </form>

                    <ConfirmationModal modal_id="promotionEditModal" modal_title="Confirmação de remoção da promoção">
                        <template v-slot:modal-text>
                            Você tem certeza que deseja excluir a promoção {{ promotion.name }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="promotionEditModalCloseButton" class="btn btn-secondary text-white"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="destroy" type="button" class="btn btn-danger text-white">Deletar promoção
                            </button>
                        </template>
                    </ConfirmationModal>

                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

