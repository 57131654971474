<template>
    <Layout page_title="VerboShop Paulo Afonso">
        <div class="col-11 mx-auto">
            <div class="d-flex justify-content-between mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb font-semibold ms-3">
                        <li class="breadcrumb-item">
                            <Link href="/">Início</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Livros</li>
                    </ol>
                </nav>
                <form class="mt-4 mt-sm-1 d-none d-sm-block me-3" action="">
                    <div>
                        <select class="form-select" aria-label="Default select example" name="orderby" id="orderby" v-model="searchForm.orderBy"
                            @change="seacher">
                            <option selected value="asc_name">Ordem alfabética - A/Z</option>
                            <option value="desc_name">Ordem alfabética - Z/A</option>
                            <option value="asc_value">Preço: menor ao maior</option>
                            <option value="desc_value">Preço: maior ao menor</option>
                        </select>
                    </div>
                </form>
                <a class="btn btn-warning text-dark mt-3 font-semibold fs-13 d-block d-sm-none" data-bs-toggle="offcanvas" href="#seacherModal"
                    role="button" aria-controls="seacher">
                    <font-awesome-icon class="me-2" :icon="['fas', 'filter']" /> Filtros
                </a>
            </div>

            <div class="row">
                <div class="col-lg-3 d-none d-sm-block">
                    <h4 class="ps-3">Autores</h4>
                    <ul class="list-group bg-transparent">
                        <li class="list-group-item border-0 bg-transparent" v-for="(author, index) in authors" :key="index">
                            <input class="form-check-input me-1" type="checkbox" :value="author.id"
                                :id="`${author.id}checkboxAuthorNormal`" @click="selectAuthor(author.clean_name)">
                            <label class="form-check-label stretched-link fs-13 ms-2" :for="`${author.id}checkboxAuthorNormal`">{{
                                author.name }} ({{ author.book_total }})</label>
                        </li>
                    </ul>
                    <h4 class="ps-3 mt-3">Temas</h4>
                    <ul class="list-group">
                        <li class="list-group-item border-0 bg-transparent" v-for="(category, index) in categories" :key="index">
                            <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                :id="`${category.id}checkboxCategoryNormal`" @click="selectCategory(category.clean_name)">
                            <label class="form-check-label stretched-link fs-13 ms-2"
                                :for="`${category.id}checkboxCategoryNormal`">{{
                                    category.name }} ({{ category.book_total }})</label>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-9">
                    <div class="row row-cols-2 row-cols-md-3 row-cols-xl-3 g-4 px-4 d-flex justify-content-center">
                        <div v-for="(book, indice) in books.data" :key="indice">
                            <Card :book="book" :displayFavorite="true" />
                        </div>
                    </div>
                    <Pagination :data="books.meta" reference_date="books" />
                </div>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="seacherModal" aria-labelledby="seacherLabel">
                    <div class="offcanvas-header border-bottom">
                        <h5 class="offcanvas-title font-bold" id="seacherLabel">Filtros</h5>
                        <button type="button" class="btn-close" ref="closeSeacherModal" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div>
                            <h4 class="ps-3">Autores</h4>
                            <ul class="list-group">
                                <li class="list-group-item border-0" v-for="(author, index) in authors" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="author.id"
                                        :id="`${author.id}checkboxAuthorCanvas`" @click="selectAuthor(author.clean_name)">
                                    <label class="form-check-label stretched-link fs-14 ms-2"
                                        :for="`${author.id}checkboxAuthorCanvas`">{{
                                            author.name }} ({{ author.book_total }})</label>
                                </li>
                            </ul>
                            <h4 class="ps-3 mt-3">Categorias</h4>
                            <ul class="list-group">
                                <li class="list-group-item border-0" v-for="(category, index) in categories" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                        :id="`${category.id}checkboxCategoryCanvas`" @click="selectCategory(category.clean_name)">
                                    <label class="form-check-label stretched-link fs-14 ms-2"
                                        :for="`${category.id}checkboxCategoryCanvas`">{{
                                            category.name }} ({{ category.book_total }})</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Card from '@/Pages/Store/Shared/Card.vue';
import Layout from '@/Pages/Store/Layouts/Layout.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const searchForm = reactive({ categories: [], authors: [], orderBy: 'asc_name' });

const props = defineProps({ categories: Object, authors: Object, books: Object });

const selectAuthor = (name) => {
    if (searchForm.authors.includes(name)) {
        searchForm.authors.pop(name)
    } else {
        searchForm.authors.push(name)
    }
    seacher()
}

const selectCategory = (name) => {
    if (searchForm.categories.includes(name)) {
        searchForm.categories.pop(name)
    } else {
        searchForm.categories.push(name)
    }
    seacher()
}

const closeSeacherModal = ref(null);

const seacher = () => {
    let authors = searchForm.authors.length >= 1 ? searchForm.authors.join(',') : '';
    let categories = searchForm.categories.length >= 1 ? searchForm.categories.join(',') : '';

    closeSeacherModal.value.click();

    router.get('/livros', {
        authors,
        categories,
        orderBy: searchForm.orderBy
    }, { preserveState: true });
}
</script>