<script setup>
import { Head, useForm } from '@inertiajs/vue3';

const form = useForm({ password: null });
const props = defineProps({ errors: Object });

const login = () => form.post('/user/confirm-password');
</script>

<template>
    <Head title="Código de confirmação" />
    <div class="vh-100 d-flex justify-content-center align-items-center bg-light">
        <div class="col-lg-5">
            <div class="card border shadow-sm p-5 rounded-3">
                <div class="row m-0">
                    <form @submit.prevent="login">
                        <img class="mx-auto d-block mb-4" width="150" src="/img/verboshop-admin-logo.png" alt="">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Senha</label>
                            <input type="password" class="form-control" name="code" id="exampleInputEmail1" v-model="form.password"
                                aria-describedby="emailHelp" autocomplete="off" placeholder="Informe sua senha para continuar">
                            <small class="text-danger mt-3" v-if="errors.password">Senha inválida, informe a senha correta para prosseguir</small>
                        </div>
                        <button class="btn btn-primary w-100 font-bold text-white mb-4" type="submit"
                            :disabled="form.processing">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Continuar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

