<script setup>
import { ref, onMounted } from "vue";
import { Head, useForm, Link } from '@inertiajs/vue3';
import Toast from '@/Pages/Dashboard/Shared/Toast.vue';

const form = useForm({
    email: null,
    password: null
});

const passwordInput = ref({
    icon: 'eye-slash',
    type: 'password'
});

const changePasswordVisibility = () => {
    passwordInput.value.type = passwordInput.value.type === 'password' ? 'text' : 'password';
    passwordInput.value.icon = passwordInput.value.type === 'password' ? 'eye-slash' : 'eye';
};

const code = ref(null);

const login = () => form.post('/login');

onMounted(() => {
    let url = new URL(window.location.href);
    let urlParams = new URLSearchParams(url.search);
    code.value = urlParams.get("code");
});
</script>

<template>
    <Head title="Login" />
    <Toast />
    <div id="login" class="d-flex justify-content-center align-items-center">
        <div class="card border-0 p-3 p-lg-0">
            <div class="row m-0">
                <div id="imageSide" class="col-lg-6 d-flex align-items-center justify-content-center d-none d-lg-block">
                </div>
                <div class="col-lg-6 col-md-12 d-flex align-items-center justify-content-center mt-lg-0">
                    <form  @submit.prevent="login">
                        <img class="mx-auto d-block mb-3" src="/img/verboshop-admin-logo.png" alt="">

                        <div class="input-group mb-3">
                            <span class="input-group-text bg-transparent"><font-awesome-icon class="text-secondary" :icon="['fas', 'at']" /></span>
                            <div class="form-floating is-invalid">
                                <input type="email" class="form-control border border-start-0" id="email"
                                    placeholder="Email" autocomplete="on" v-model="form.email" required>
                                <label for="email">Email</label>
                            </div>
                        </div>

                        <div class="input-group mb-3">
                            <span class="input-group-text bg-transparent" @click="changePasswordVisibility">
                                <font-awesome-icon class="text-secondary" id="iconPassword" :icon="['fas', passwordInput.icon]" />
                            </span>
                            <div class="form-floating">
                                <input :type="passwordInput.type" class="form-control border border-start-0" id="password"
                                    placeholder="Senha" autocomplete="off" name="password" v-model="form.password" required>
                                <label for="password">Senha</label>
                            </div>
                        </div>

                        <div class="alert alert-danger text-center d-flex align-items-center justify-content-center"
                            role="alert" v-if="form.errors.email">
                            {{ form.errors.email }} <font-awesome-icon class="ms-2" :icon="['fas', 'circle-exclamation']" />
                        </div>

                        <Link class="mb-3 fs-14" href="/forgot-password">
                        Esqueceu sua senha?
                        </Link>

                        <button class="btn px-3 py-2 mt-3 text-white w-100 button-submit" type="submit"
                            :disabled="form.processing">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Acessar conta
                        </button>

                        <Link class="btn btn-light px-3 py-2 mt-3 text-dark w-100 d-flex align-items-center justify-content-center" href="/dashboard/profile/sso/google" style="border-style: dashed">
                            <img src="/img/google-icon.png" class="logo-google me-3" alt=""> <span>Entrar com a conta do Google</span> 
                        </Link>

                        <small class="text-center mt-3 copyright d-block font-regular">
                            Direitos reservados: VerboShop Paulo Afonso <font-awesome-icon class="ms-2" :icon="['fas', 'copyright']" />
                        </small>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

