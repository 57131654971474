<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import usePreviewImage from '@/Composables/PreviewImage.vue';
import Lear from './Components/Lear.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const form = useForm({
    name: null,
    profile_picture_url: null,
    personal_description: null
});

const store = () => form.post('/dashboard/authors');
</script>

<template>
    <Dashboard page_title="Criar autor">
        <Breadcrumb currentPageName="Criar autor" />
        <PageNavigationHeader pageTitle="Criar autor" baseRoute="authors" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store" id="author-creation-form">
                        <div class="row g-3">
                            <div class="col-md-12">
                                <label for="name" class="form-label">Nome do autor</label>
                                <input type="text" class="form-control" id="name" name="name" v-model="form.name"
                                    placeholder="Bud Wright" autocomplete="off" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="personal-description" class="form-label">Resumo pessoal</label> <small>(opcional)</small>
                                <textarea type="text" class="form-control" id="personal-description"
                                    name="personal_description" placeholder="Forneça um breve resumo pessoal deste autor"
                                    v-model="form.personal_description" rows="5"></textarea>
                                <small class="text-danger mt-3" v-if="form.errors.personal_description">{{
                                    form.errors.personal_description
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="formFile" class="form-label">Selecione uma foto para o
                                    perfil desse autor - Tamanho máximo permitido: 2mb</label>
                                <input class="form-control" @input="form.profile_picture_url = $event.target.files[0]"
                                    type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage" required>
                                <small class="text-danger mt-3" v-if="form.errors.profile_picture_url">{{
                                    form.errors.profile_picture_url
                                }}</small>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <img class="d-block mx-auto preview-image rounded-circle" v-if="imageUrl" :src="imageUrl" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#author-creation" role="button" aria-controls="author-creation">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success float-end" type="submit"
                                    :disabled="form.processing || imageSizeInMB > 2">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar autor
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Lear />
        </div>
    </Dashboard>
</template>

