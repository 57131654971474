<script setup>
import { computed } from "vue";
import { Link, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    user: Object
});
</script>

<template>
    <Dashboard :page_title="user.name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="user.name" :linkList="[
                { previous_page_url: 'users', previous_page_name: 'Equipe' }
            ]" />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-5">
                            <h4 class="font-bold m-0">{{ user.name }}</h4>
                        </div>
                        <div v-if="permissions.edit_user" class="col-sm-7 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon
                                :href="`/dashboard/users/edit/${user.id}`" 
                                background="primary" 
                                color="white"
                                icon="pen-to-square" 
                                message="Editar membro"
                                additionalStyles="me-2"
                            />
                            <Link as="button" method="post" :href="`/dashboard/users/reset-password/${user.id}`"
                                class="btn btn-warning text-dark font-semibold fs-14">
                                <font-awesome-icon class="me-2" :icon="['fas', 'key']" /> Resetar senha 
                            </Link>
                        </div>
                    </div>
                    <hr>
                    <form class="border-0 shadow-none">
                        <img v-if="user.avatar" :src="`${user.avatar}`"
                    class="mx-auto d-block profile-image border border-5 my-4 rounded-circle" alt="...">
                        <fieldset disabled>
                            <div class="row g-4">
                                <div class="col-sm-4">
                                    <label for="name" class="form-label">Nome</label>
                                    <input type="text" id="name" autocomplete="off" class="form-control" :value="user.name">
                                </div>
                                <div class="col-sm-4">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="text" autocomplete="off" id="email" class="form-control" :value="user.email">
                                </div>
                                <div class="col-sm-4">
                                    <label for="status" class="form-label">Função</label>
                                    <input type="text" autocomplete="off" id="status" class="form-control" :value="user.role">
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

