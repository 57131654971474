<script setup>
import { reactive, onMounted } from "vue";
import { router, Link } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import FormatBadges from '@/Pages/Dashboard/Shared/FormatBadges.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';

const props = defineProps({
    orders: Object,
    clients: Object,
    books: Object,
    clientOrders: Object
});

const searchForm = reactive({
    status: '',
    order_id: '',
    client_id: '',
    book_id: ''
});

const seacher = () => router.get('/dashboard/client-orders', searchForm, { preserveState: true });

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('status')) {
        searchForm.status = urlParams.get('status');
    }
    if (urlParams.has('order_id')) {
        searchForm.order_id = urlParams.get('order_id');
    }
    if (urlParams.has('client_id')) {
        searchForm.client_id = urlParams.get('client_id');
    }
    if (urlParams.has('book_id')) {
        searchForm.book_id = urlParams.get('book_id');
    }
})
</script>

<template>
    <Dashboard page_title="Listagem de pedidos dos clientes">
        <Breadcrumb currentPageName="Listagem de pedidos dos clientes" />
        <PageNavigationHeader pageTitle="Pedidos" baseRoute="client-orders" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto py-3">
                        <div class="row g-3">
                            <div class="col-sm-8">
                                <label for="client_id" class="form-label">Cliente</label>
                                <select class="form-select" id="client_id" v-model="searchForm.client_id">
                                    <option selected value="">Todos os clientes</option>
                                    <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
                                </select>
                            </div>
                            <div class="col-6 col-sm-4">
                                <label for="order_id" class="form-label">Pedido</label>
                                <select class="form-select" id="order_id" v-model="searchForm.order_id">
                                    <option selected value="">Todos os pedidos</option>
                                    <option v-for="(order, index) in orders" :key="index" :value="order.id">Pedido #{{ order.id }}</option>
                                </select>
                            </div>
                            <div class="col-6 col-sm-8">
                                <label for="book_id" class="form-label">Livros</label>
                                <select class="form-select" id="book_id" v-model="searchForm.book_id">
                                    <option selected value="">Todos os pedidos</option>
                                    <option v-for="(book, index) in books" :key="index" :value="book.id">{{ book.name }}</option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <label for="status" class="form-label">Status do pedido</label>
                                <select class="form-select" id="status" v-model="searchForm.status">
                                    <option selected value="">Todos os status</option>
                                    <option value="PENDENTE">Pendente</option>
                                    <option value="VINCULO_REALIZADO">Vinculado a pedido</option>
                                    <option value="LIVRO_DISPONIVEL">Livro disponível</option>
                                    <option value="LIVRO_ENTREGUE">Livro entregue</option>
                                    <option value="CANCELADO">Cancelado</option>
                                </select>
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary float-end text-white mt-4" @click="seacher">
                             <font-awesome-icon class="me-2" :icon="['fas', 'magnifying-glass']" /> Buscar
                            </a>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive col-11 mx-auto">
                        <table class="table table-hover" id="tableOrders">
                            <thead>
                                <tr>
                                    <th class="text-center" scope="col">ID</th>
                                    <th class="text-center" scope="col">Status</th>
                                    <th class="text-center" scope="col">Cliente</th>
                                    <th class="text-center" scope="col">Usuário</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="clientOrders.meta.total > 0">
                                <tr v-for="(order, index) in clientOrders.data" :key="index">
                                    <td class="text-center">#{{ order.id }}</td>
                                    <td class="text-center">
                                        <FormatBadges :badge="order.status" />
                                    </td>
                                    <td class="text-center">{{ order.client.name}}</td>
                                    <td class="text-center">
                                        <img class="rounded-circle" :src="order.user.avatar" width="30" alt="">
                                    </td>
                                    <td class="text-center">{{ order.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/client-orders/${order.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link  :href="`/dashboard/client-orders/edit/${order.id}`"
                                            class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="6" class="p-3 font-semibold">
                                    Nenhum pedido encontrado
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="clientOrders.meta.total > 0">
                                    <td class="text-end" colspan="6">
                                        <span class="fs-14 font-semibold">{{ clientOrders.meta.total }} pedidos
                                            encontrados
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="clientOrders.meta" reference_date="clientOrders" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

