<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import FormatBadges from '@/Pages/Dashboard/Shared/FormatBadges.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    client: Object
});

const { convertToCurrencyFormat } = usePriceFormatter();
</script>

<template>
    <Dashboard :page_title="client.name">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="client.name" :linkList="[
                { previous_page_url: 'clients', previous_page_name: 'Clientes' }
            ]" />
            <div class="bg-white p-4 rounded-3 border" id="show-client">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-lg-4 col-6">
                        <FormatBadges :badge="client.type" />
                    </div>
                    <div class="col-lg-8 col-6 text-end" v-if="permissions.edit_client">
                        <ButtonWithIcon :href="`/dashboard/clients/edit/${client.id}`" background="primary" color="white"
                            icon="pen-to-square" message="Editar" />
                    </div>
                </div>
                <hr>
                <div>
                    <img v-if="client.avatar" :src="`${client.avatar}`"
                        class="mx-auto d-block profile-image border border-5 my-4 rounded-circle" alt="...">
                    <h4 class="text-center">{{ client.name }}</h4>
                </div>
                <div class="row d-flex justify-content-between mt-4">
                    <div class="col"><span><strong>Email:</strong> {{ client.email ?? 'Não informado' }}</span></div>
                    <div class="col"><span><strong>Telefone:</strong> {{ client.phone_number ?? 'Não informado' }}</span></div>
                </div>
                <hr>
                <ul class="nav nav-tabs d-flex justify-content-center mt-4" id="myTab" role="tablist"
                    v-if="Object.values(client.shopping).length">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data-tab-pane"
                            type="button" role="tab" aria-controls="data-tab-pane"
                            :disabled="!Object.values(client.shopping).length" aria-selected="true"><font-awesome-icon
                                class="me-2" :icon="['fas', 'basket-shopping']" />
                            Compras</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                            data-bs-target="#requested-books-tab-pane" :disabled="!client.client_order.length" type="button"
                            role="tab" aria-controls="requested-books-tab-pane" aria-selected="false"><font-awesome-icon
                                class="me-2" :icon="['fas', 'book']" />
                            Livros solicitados</button>
                    </li>

                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active col-11 mx-auto py-3" id="data-tab-pane" role="tabpanel"
                        aria-labelledby="data-tab" tabindex="0">
                        <div class="row" v-if="Object.values(client.shopping).length > 0">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Valor</th>
                                            <th class="text-center" scope="col">Método</th>
                                            <th class="text-center" scope="col">Vendedor</th>
                                            <th class="text-center" scope="col">Data</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(purchase, index) in client.shopping" :key="index">
                                            <td>#{{ purchase.id }}</td>
                                            <td>{{ convertToCurrencyFormat(purchase.total_sale_price) }}</td>
                                            <td class="text-center">{{ purchase.payment_method }}</td>
                                            <td class="text-center">
                                                <img class="rounded-circle" width="25" :src="purchase.user_avatar" alt="">
                                            </td>
                                            <td class="text-center">{{ purchase.created_at }}</td>
                                            <td class="text-center">
                                                <Link :href="`/dashboard/sales/${purchase.id}`" class="btn btn-success">
                                                <font-awesome-icon :icon="['fas', 'eye']" />
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade col-11 mx-auto py-3 overflow-auto" id="requested-books-tab-pane" role="tabpanel"
                        aria-labelledby="requested-books-tab-pane" tabindex="0" v-if="client.client_order.length">
                        <div class="card mt-3 p-3" style="border-style: dashed"
                            v-for="(client_order, index) in client.client_order" :key="index">
                            <div class="row d-flex justify-content-between">
                                <div class="col-lg-9 col-12">
                                    <h5 class="mt-0">{{ client_order.book?.name ?? 'Não vinculado' }}</h5>
                                    <p>{{ client_order.client.name }} solicitou o pedido de {{ client_order.quantity }}
                                        unidade(s) do livro em {{ client_order.created_at }}. </p>
                                    <Link :href="`/dashboard/client-orders/${client_order.id}`" class="stretched-link">Ir
                                    para pedido</Link> 
                                </div>
                                <div class="col-lg-3 col-12 d-flex align-items-center justify-content-end" v-if="client_order.book">
                                    <img width="100" :src="`/storage/${client_order.book.cover_image_url}`" class="me-3"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </Dashboard>
</template>

