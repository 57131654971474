<script setup>
import { Link, useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import { VMoney } from 'v-money';
import { vMaska } from "maska";

import useMoney from '@/Composables/Money.vue';
import useMessage from '@/Composables/Message.vue';
import usePriceFormatter from '@/Composables/PriceFormatter.vue';
import Lear from "../Components/Lear.vue";

const props = defineProps({
    books: Object,
    errors: Object,
    order: Object,
});

const { message } = useMessage();
const { money } = useMoney(3);
const { convertDecimalFormat } = usePriceFormatter();

const form = useForm({
    book_id: '',
    order_id: props.order.id,
    quantity: '',
    unit_price: '',
    total_books_price: ''
})

const getTotalPriceBooks = () => form.total_books_price = (convertDecimalFormat(form.unit_price) * form.quantity).toFixed(3);

const store = () => {
    form.transform((data) => ({
        ...data,
        unit_price: convertDecimalFormat(data.unit_price),
        total_books_price: convertDecimalFormat(data.total_books_price),
    })).post(`/dashboard/book-orders`);
};
</script>

<template>
    <Dashboard page_title="Adicionar pedido">
        <div class="row mt-2 mx-0">
            <nav class="p-0" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <Link :href="`/dashboard/orders/${order.id}`">Pedido nº {{ order.id }}</Link>
                    </li>
                </ol>
            </nav>
            <div class="col-lg-12 bg-white rounded-3 border">
                <form class="p-4 mb-3" @submit.prevent="store" v-if="books.length > 0">
                    <div class="row g-3">
                        <div class="col-md-12">
                            <label for="book_id" class="form-label">Livro</label>
                            <select class="form-select" name="book_id" v-model="form.book_id" required>
                                <option value="" selected>--- Selecione algum livro ---</option>
                                <option :value="book.id" v-for="(book, index) in books" :key="index">{{
                                    book.name }}
                                </option>
                            </select>
                            <small class="text-danger mt-3" v-if="form.errors.book_id">{{ form.errors.book_id
                            }}</small>
                        </div>
                        <div class="col-6 col-md-3">
                            <label for="unit_price" class="form-label">Preço da unidade</label>
                            <div class="input-group">
                                <input type="text" class="form-control" autocomplete="off" id="unit_price" name="unit_price"
                                    @keyup="getTotalPriceBooks()" v-model="form.unit_price" v-money="money" maxlength="7"
                                    required>
                                <small class="text-danger mt-3" v-if="form.errors.unit_price">{{
                                    form.errors.unit_price
                                }}</small>
                            </div>
                        </div>
                        <div class="col-6 col-md-3">
                            <label for="quantity" class="form-label">Quantidade</label>
                            <input type="text" class="form-control" autocomplete="off" v-model="form.quantity" @keyup="getTotalPriceBooks()"
                                id="quantity" name="quantity" required v-maska data-maska="##">
                            <small class="text-danger mt-3" v-if="form.errors.quantity">{{ form.errors.quantity
                            }}</small>
                        </div>
                        <div class="col-md-3">
                            <label for="total_books_price" class="form-label">Valor total</label>
                            <div class="input-group">
                                <input type="text" disabled class="form-control" id="total_books_price"
                                    name="total_books_price" v-model="form.total_books_price" v-money="money" required>
                                <small class="text-danger mt-3" v-if="form.errors.total_books_price">{{
                                    form.errors.total_books_price
                                }}</small>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex align-items-end">
                            <button class="btn btn-success float-end w-100" type="submit" :disabled="form.processing">
                                <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                    aria-hidden="true"></span>
                                Adicionar
                            </button>
                        </div>
                    </div>
                </form>

                <div class="alert alert-info mx-0 borde" role="alert" v-if="books.length <= 0">
                    <p class="my-0"><font-awesome-icon class="me-2"
                                :icon="['fas', 'circle-info']" /> Todos os livros da loja já foram
                        adicionados a esse pedido</p>
                </div>
            </div>    
            <Lear />
        </div>
        <div class="lear-button">
            <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas" href="#order-lear"
                role="button" aria-controls="order-lear">
                Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
            </a>
        </div>
    </Dashboard>
</template>

