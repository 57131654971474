
<script>
import { ref } from 'vue';

export default function useMoney(precision = 2) {

    const money = ref({
        decimal: ',',
        thousands: '.',
        precision: precision,
        masked: false
    });

    return {
        money
    }
}
</script>
  