<template>
<header class="fixed-top" id="main-navbar">
    <nav class="navbar navbar-expand-lg border-0 p-2 border-bottom d-none d-md-block">
        <div class="container-fluid">
            <Link class="navbar-brand order-2 order-md-1 col-2 m-0" href="/" aria-label="Logo Verboshop">
            <img src="/img/verboshop-logo.png">
            </Link>
            <a class="d-md-none order-1 text-white" href="#"><font-awesome-icon :icon="['fas', 'bag-shopping']" /></a>
            <div class="order-md-2 col text-center">
                <form class="d-none d-md-flex mx-auto" role="search">
                    <input class="form-control" name="seacher" type="search" placeholder="O que você está buscando?"
                        aria-label="Search" data-bs-toggle="modal" data-bs-target="#bookSearchModal">
                </form>
            </div>
            <div class="text-center text-white order-3 order-md-3 col-2">
                <font-awesome-icon class="d-block w-100" :icon="['fas', 'bag-shopping']" />
                <a data-bs-toggle="offcanvas" href="#favorites" role="button" aria-controls="favorites"
                    class="fs-14 text-decoration-none font-bold d-none d-md-block text-white">
                    Meus favoritos
                </a>
            </div>
        </div>
    </nav>

    <nav class="navbar navbar-expand-lg d-block d-md-none p-2">
        <div class="container-fluid">
            <div class="d-flex align-items-center justify-content-between w-100">
                <button class="navbar-toggler border-white" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <font-awesome-icon class="text-warning" :icon="['fas', 'bars']" />
                </button>
                <Link class="navbar-brand" href="/" aria-label="Logo Verboshop">
                <img src="/img/verboshop-logo.png" alt="">
                </Link>
                <a data-bs-toggle="offcanvas" href="#favorites" role="button" aria-controls="favoritesExample"
                    class="fs-14 text-decoration-none font-bold text-white">
                    <font-awesome-icon class="d-block fs-4" :icon="['fas', 'bag-shopping']" />
                </a>
            </div>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 secondary-navbar">
                    <li class="nav-item">
                        <Link href="/" :class="{ 'text-warning': $page.url === '/' }" class="nav-link fs-14">Início
                        </Link>
                    </li>
                    <li class="nav-item dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
                        <a class="nav-link dropdown-toggle fs-14"
                            :class="{ 'active': $page.url.startsWith('/autores') }" href="#" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Autores
                        </a>
                        <ul class="dropdown-menu">
                            <li v-for="(item, index) in authors" :key="index">
                                <Link class="dropdown-item" :href="`/autores/${item.clean_name}`">{{ item.name }}</Link>
                            </li>
                            <li>
                                <Link class="dropdown-item" :href="`/autores`">Ver todos os autores</Link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
                        <a class="nav-link dropdown-toggle fs-14" :class="{ 'active': $page.url.startsWith('/temas') }"
                            href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Temas
                        </a>
                        <ul class="dropdown-menu">
                            <li v-for="(item, index) in themes" :key="index">
                                <Link class="dropdown-item" :href="`/temas/${item.clean_name}`">{{ item.name }}</Link>
                            </li>
                            <li>
                                <Link class="dropdown-item" :href="`/temas`">Ver todos os temas</Link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <Link href="/livros" :class="{ 'text-warning': $page.url.startsWith('/livros') }"
                            class="nav-link fs-14">
                        Todos os Livros</Link>
                    </li>
                    <li class="nav-item">
                        <Link href="/login" :class="{ 'text-warning': $page.url.startsWith('/login') }"
                            class="nav-link fs-14">
                            Painel Administrativo
                    </Link>
                    </li>
                </ul>
            </div>

            <div class="col-12 my-3">
                <form class="w-100" role="search">
                    <input class="form-control me-2" type="search" name="seacher"
                        placeholder="O que você está buscando?" data-bs-toggle="modal"
                        data-bs-target="#bookSearchModal">
                </form>
            </div>
        </div>

    </nav>

    <ul class="nav text-center p-1 d-none d-md-flex justify-content-center secondary-navbar">
        <li class="nav-item">
            <Link href="/" :class="{ 'text-warning': $page.url === '/' }" class="nav-link fs-14">Início</Link>
        </li>
        <li class="nav-item dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
            <a class="nav-link dropdown-toggle fs-14" :class="{ 'active': $page.url.startsWith('/autores') }" href="#"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Autores
            </a>
            <ul class="dropdown-menu">
                <li v-for="(item, index) in authors" :key="index">
                    <Link class="dropdown-item" :href="`/autores/${item.clean_name}`">{{ item.name }}</Link>
                </li>
                <li>
                    <Link class="dropdown-item" :href="`/autores`">Ver todos os autores</Link>
                </li>
            </ul>
        </li>
        <li class="nav-item dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
            <a class="nav-link dropdown-toggle fs-14" :class="{ 'active': $page.url.startsWith('/temas') }" href="#"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Temas
            </a>
            <ul class="dropdown-menu">
                <li v-for="(item, index) in themes" :key="index">
                    <Link class="dropdown-item" :href="`/temas/${item.clean_name}`">{{ item.name }}</Link>
                </li>
                <li>
                    <Link class="dropdown-item" :href="`/temas`">Ver todos os temas</Link>
                </li>
            </ul>
        </li>
        <li class="nav-item">
            <Link href="/livros" :class="{ 'active': $page.url.startsWith('/livros') }" class="nav-link fs-14">Todos os
            livros</Link>
        </li>
    </ul>
</header>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const page = usePage();
const themes = computed(() => page.props.store.themes);
const authors = computed(() => page.props.store.authors);

const showDropdown = (event) => {
    event.currentTarget.classList.add('show-dropdown');
}

const hideDropdown = (event) => {
    event.currentTarget.classList.remove('show-dropdown');
}
</script>
