<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const page = usePage();
const permissions_user = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    role: Object, 
    permissions: Object
});
</script>

<template>
    <Dashboard :page_title="role.display_name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="role.display_name" :linkList="[
                { previous_page_url: 'roles', previous_page_name: 'Funções' }
            ]" />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">{{ role.display_name }}</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon v-if="permissions_user.edit_role"
                                :href="`/dashboard/roles/edit/${role.id}`" 
                                background="primary" 
                                color="white"
                                icon="pen-to-square" 
                                message="Editar permissão"
                                additionalStyles="me-2"
                            />
                        </div>
                    </div>
                </div>
                <div class="card mt-4 normal-listing overflow-auto" v-if="Object.values(permissions).length > 0">
                    <div class="card-header font-bold p-3 bg-transparent">As permissões atribuídas a esta função são as
                        seguintes:</div>
                    <div class="card-body p-0">
                        <ul class="list-group list-group-flush">
                            <li v-for="(permission, index) in permissions" :key="index"
                                class="list-group-item font-semibold fs-14 p-3 d-flex align-items-center justify-content-between">
                                {{ permission.display_name }} - {{ permission.description }} <span
                                    class="badge text-bg-success p-2">{{ permission.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

