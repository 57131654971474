<script setup>
import { onMounted, ref, computed } from "vue";
import { usePage, useForm, router } from '@inertiajs/vue3';

import usePreviewImage from '@/Composables/PreviewImage.vue';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const props = defineProps({
    author: Object,
    errors: Object,
});

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const formEditAuthor = useForm({
    name: props.author.name,
    personal_description: props.author.personal_description,
    profile_picture_url: '',
    _method: 'put'
});

const authorEditModalCloseButton = ref(null);

const update = () => formEditAuthor.post(`/dashboard/authors/${props.author.id}`);
const destroy = () => {
    authorEditModalCloseButton.value.click();
    router.delete(`/dashboard/authors/${props.author.id}`);
};

onMounted(() => {
    imageUrl.value = `/storage/${props.author.profile_picture_url}`;
});
</script>

<template>
    <Dashboard :page_title="author.name">
        <div class="row mx-0" id="edit-author">
            <Breadcrumb :currentPageName="author.name" :linkList="[
                { previous_page_url: 'authors', previous_page_name: 'Autores' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-lg-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do autor</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon 
                                :href="`/dashboard/authors/${author.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />
                            
                            <ButtonWithIcon v-if="permissions.destroy_author" 
                                href="#" 
                                background="danger" 
                                color="white"
                                icon="trash-can" 
                                :linkInertia="false" 
                                message="Deletar"
                                dataBsToggle="modal"
                                dataBsTarget="#authorEditModal"
                            />
                        </div>
                    </div>
                    <hr>
                    <form class="accordion shadow-none mt-4" id="author-data-editing-accordion" @submit.prevent="update">
                        <div class="row g-3 mb-3">
                            <div class="col-md-12">
                                <label for="name" class="form-label">Nome do autor</label>
                                <input type="text" class="form-control" autocomplete="off" id="name" name="name"
                                    v-model="formEditAuthor.name" required>
                                <small class="text-danger mt-3" v-if="formEditAuthor.errors.name">{{
                                    formEditAuthor.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="personal-description" class="form-label">Resumo
                                    pessoal</label>
                                <textarea type="text" class="form-control" id="personal-description"
                                    name="personal_description" v-model="formEditAuthor.personal_description"
                                    rows="10"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <label for="formFile" class="form-label">Selecione uma nova foto para o perfil do
                                autor - Tamanho máximo permitido: 2mb</label>
                            <input class="form-control" @input="formEditAuthor.profile_picture_url = $event.target.files[0]"
                                type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage">
                            <small class="text-danger mt-3" v-if="formEditAuthor.errors.profile_picture_url">{{
                                formEditAuthor.errors.profile_picture_url
                            }}</small>

                        </div>
                        <div class="col-md-11 mx-auto mt-4">
                            <img class="d-block mx-auto preview-image border border-5 rounded-circle"
                                v-if="imageUrl != '/storage/null'" :src="imageUrl" />
                        </div>

                        <button class="btn btn-success float-end mt-3 mt-sm-0" type="submit" :disabled="formEditAuthor.processing || imageSizeInMB > 2">
                            <span v-if="formEditAuthor.processing" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Atualizar dados
                        </button>
                    </form>

                    <ConfirmationModal modal_id="authorEditModal" modal_title="Confirmação de remoção do autor">
                        <template v-slot:modal-text>
                            Você tem certeza que deseja excluir o autor {{ author.name }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="authorEditModalCloseButton" class="btn btn-secondary text-white"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="destroy" type="button" class="btn btn-danger text-white">Deletar autor
                            </button>
                        </template>
                    </ConfirmationModal>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

