<script setup>
import { onMounted, computed } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3'; 

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import usePreviewImage from '@/Composables/PreviewImage.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const props = defineProps({
    user: Object,
    userRole: Object,
    roles: Object
});

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const form = useForm({
    name: props.user.name,
    status: props.user.status,
    role_id: props.userRole.id || 1,
    profile_image_url: '',
    email: props.user.email,
    _method: 'put'
});

const update = () => form.post(`/dashboard/users/${props.user.id}`);

onMounted(() => {
    imageUrl.value = `${props.user.avatar}`;
});
</script>

<template>
    <Dashboard :page_title="user.name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="user.name" :linkList="[
                { previous_page_url: 'users', previous_page_name: 'Equipe' }
            ]" />
            
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do membro</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon 
                                :href="`/dashboard/users/${user.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />
                        </div>
                    </div>
                    <hr>

                    <form class="border-0 shadow-none" @submit.prevent="update">
                        <div class="row g-4">
                            <div class="col-sm-6 col">
                                <label for="name" class="form-label">Nome</label>
                                <input type="text" class="form-control" autocomplete="off" id="name" name="name"
                                    v-model="form.name" placeholder="Matheus" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-sm-6 col">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" class="form-control" autocomplete="off" id="email" name="email"
                                    v-model="form.email" placeholder="matheus@gmail.com" required>
                                <small class="text-danger mt-3" v-if="form.errors.email">{{ form.errors.email
                                }}</small>
                            </div>
                            <div class="col-sm-6 col-md-3" v-if="permissions.edit_user_role">
                                <label for="function" class="form-label">Função</label>
                                <select class="form-select" :disabled="!permissions.edit_user" name="function" id="function" v-model="form.role_id">
                                    <option v-for="(role, index) in roles" :key="index" :value="role.id">{{
                                        role.display_name }}
                                    </option>
                                </select>
                                <small class="text-danger mt-3" v-if="form.errors.role">{{ form.errors.role
                                }}</small>
                            </div>
                            <div class="col-sm-6 col-md-3" v-if="permissions.edit_user_status">
                                <label for="status" class="form-label">Status da conta</label>
                                <select class="form-select" :disabled="!permissions.edit_user" name="status" id="status" v-model="form.status">
                                    <option value="ATIVO">Ativa</option>
                                    <option value="DESATIVADO">Desativada</option>
                                </select>
                                <small class="text-danger mt-3" v-if="form.errors.status">{{ form.errors.status
                                }}</small>
                            </div>
                            <div class="col-md-6">
                                <label for="formFile" class="form-label">Selecione uma foto</label>
                                <input class="form-control" @input="form.profile_image_url = $event.target.files[0]"
                                    type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage">
                                <small class="text-danger mt-3" v-if="form.errors.profile_image_url">{{
                                    form.errors.profile_image_url
                                }}</small>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <img class="d-block mx-auto preview-image rounded-circle" v-if="imageUrl" :src="imageUrl" />
                            </div>
                        </div>

                        <button class="btn btn-success float-end mt-3 mt-sm-0" type="submit"
                            :disabled="form.processing || imageSizeInMB > 2">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Atualizar dados
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

