<script setup>
import { reactive, onMounted, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import FormatBadges from '@/Pages/Dashboard/Shared/FormatBadges.vue';

const props = defineProps({
    clients: Object
});

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const searchForm = reactive({ name: null, type: '' });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
}

const seacher = () => router.get('/dashboard/clients', searchForm, { preserveState: true });

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('name')) {
        searchForm.name = urlParams.get('name');
    }
});
</script>

<template>
    <Dashboard page_title="Listagem de clientes">
        <Breadcrumb currentPageName="Listagem de clientes" />
        <PageNavigationHeader pageTitle="Clientes" baseRoute="clients" :showAdd="permissions.create_client" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto p-3">
                        <div class="d-flex justify-content-between align-items-center mt-2 row">
                            <div class="font-bold col-12 col-sm-6">
                                Baixar listagem de clientes
                            </div>
                            <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                                <!-- <a :href="`/dashboard/clients/xlxs/export`"  class="btn btn-success me-2"><i
                                        class="bi bi-filetype-xlsx me-2"></i> Excel</a> -->
                                <ButtonWithIcon 
                                    :href="`/dashboard/clients/pdf/export`" 
                                    background="danger" 
                                    color="white"
                                    icon="file" 
                                    :linkInertia="false" 
                                    message="Baixar em PDF" 
                                />
                            </div>
                        </div>
                        <hr>
                        <div class="row g-3">
                            <div class="col-lg-8 mb-3">
                                <label for="name" class="form-label">Nome do cliente</label>
                                <input type="text" autocomplete="on" id="name" class="form-control"
                                    placeholder="Gabriela Barbosa" v-model="searchForm.name"
                                    @input="compositionSeacher('name', $event)">
                            </div>
                            <div class="col-sm-4">
                                <label for="type" class="form-label">Tipo do cliente</label>
                                <select class="form-select" id="type" @change="seacher" v-model="searchForm.type">
                                    <option selected value="">Todos os tipos</option>
                                    <option value="COMUM">Comum</option>
                                    <option value="PASTOR">Pastor</option>
                                    <option value="ALUNO_RHEMA">Aluno Rhema</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive-sm col-11 mx-auto">
                        <table class="table table-hover" id="table-clients">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Tipo</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="clients.meta.total > 0">
                                <tr v-for="(client, index) in clients.data" :key="index">
                                    <td scope="row">{{ parseInt(index) + 1 }}</td>
                                    <td>
                                        <img class="rounded-circle me-2" width="25" :src="client.avatar" alt="">
                                        {{ client.name }}
                                    </td>
                                    <td class="text-center">
                                        <FormatBadges :badge="client.type" />
                                    </td>
                                    <td class="text-center">{{ client.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/clients/${client.id}`" class="btn btn-success">
                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_client" :href="`/dashboard/clients/edit/${client.id}`"
                                            class="btn btn-primary ms-2">
                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhum cliente encontrado
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="clients.meta.total > 0">
                                    <td class="text-end" colspan="6">
                                        <span class="fs-14 font-semibold">{{ clients.meta.total }} clientes
                                            encontrados
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="clients.meta" reference_date="clients" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

