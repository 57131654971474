<script setup>
import { computed, defineEmits } from 'vue';
import { router, usePage, Link } from '@inertiajs/vue3';

const page = usePage();
const user = computed(() => page.props.auth.user);

const emit = defineEmits('changeStateResponsiveSidebar');

const logout = () => router.post('/logout');
const changeStateResponsiveSidebar = () => emit('changeStateResponsiveSidebar');
</script>

<template>
    <nav class="navbar">
        <a href="#" class="sidebar-toggler" @click="changeStateResponsiveSidebar">
            <font-awesome-icon class="text-dark" :icon="['fas', 'bars']" />
        </a>
        <div class="navbar-content">
                <form class="m-0 search-form">
                    <div class="input-group mt-2 mt-md-0">
                        <div class="input-group-text">
                            <font-awesome-icon class="mt-1" :icon="['fas', 'magnifying-glass']" />
                        </div>
                        <input type="text" class="form-control" id="navbarForm" placeholder="Buscar" data-bs-toggle="modal" data-bs-target="#bookSearchModal">
                    </div>
                </form>
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle border border-warning"
                            :src="user.avatar"
                            alt="Profile Photo">
                            <span class="fs-13 ms-3">{{ user.name }}</span>
                    </a>
                    <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
                        <div class="d-flex flex-column align-items-center border-bottom px-5 py-2">
                            <div class="mb-3">
                                <img class="rounded-circle"
                                    :src="user.avatar"
                                    alt="Profile Photo">                       
                            </div>
                            <div class="text-center">
                                <p class="fw-bolder m-0 fs-13">{{ user.name }}</p>
                                <p class="fs-13">{{ user.email }}</p>
                            </div>
                        </div>
                        <ul class="list-unstyled p-1">
                            <li class="dropdown-item">
                                <Link href="/dashboard/profile" class="text-decoration-none ms-0 d-block p-2">
                                    <font-awesome-icon class="me-3" :icon="['fas', 'sliders']" />
                                    <span class="fs-13">Perfil</span>
                                </Link>
                            </li>
                            <li class="dropdown-item">
                                <a href="#" class="text-decoration-none ms-0 d-block p-2">
                                    <font-awesome-icon class="me-3" :icon="['fas', 'right-from-bracket']" />
                                    <span class="fs-13" @click="logout">Sair da conta</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

