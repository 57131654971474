<template>
    <div>
        <img class="mx-auto d-block profile-image border border-5 my-4 rounded-circle" :src="imageUrl" alt="" @error="handleImageError" />
    </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue';

const defaultImageUrl = '/img/author-default-image.jpg';
const props = defineProps(['url']);
const imageUrl = ref(props.url || defaultImageUrl);

watch(() => props.url, (value) => {
    imageUrl.value = value || defaultImageUrl;
});

const handleImageError = () => {
    imageUrl.value = defaultImageUrl;
};
</script>
