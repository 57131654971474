<script setup>
import { useForm } from '@inertiajs/vue3';
import { watchEffect, ref } from "vue";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Lear from './Components/Lear.vue';

import { vMaska } from "maska";

const props = defineProps({
    categories: Object,
    books: Object
});

const form = useForm({
    name: null,
    discount_percentage: null,
    active: 0,
    rhema: 0,
    categories: [],
    books: []
});

const store = () => form.post('/dashboard/promotions');

const limitPercentage = () => {
    if (form.discount_percentage > 100) {
        form.discount_percentage = 100;
    }
}

const selectAllBooks = ref(false);

watchEffect(() => {
    if (selectAllBooks.value) {
        form.books = props.books.map(book => book.id);
    } else {
        form.books = [];
    }
});
</script>

<template>
    <Dashboard page_title="Criar promoção">
        <Breadcrumb currentPageName="Criar promoção" />
        <PageNavigationHeader pageTitle="Criar promoção" baseRoute="promotions" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-5">
                                <label for="name" class="form-label">Nome da promoção</label>
                                <input type="text" autocomplete="on" class="form-control" id="name" name="name"
                                    placeholder="Dia das mães" v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>

                            <div class="col-md-3 col-6">
                                <label for="rhema" class="form-label">Rhema</label>
                                <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <div class="col-md-2 col-6">
                                <label for="discount_percentage" class="form-label">Desconto</label>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1">%</span>
                                    <input type="text" placeholder="10" class="form-control" id="discount_percentage"
                                        name="discount_percentage" v-model="form.discount_percentage" v-maska
                                        data-maska="##" autocomplete="off" required @keyup="limitPercentage()">
                                </div>
                                <small class="text-danger mt-2" v-if="form.errors.discount_percentage">{{
                                    form.errors.discount_percentage
                                }}</small>
                            </div>

                            <div class="col-md-2 col-6">
                                <label for="active" class="form-label">Ativa</label>
                                <select class="form-select" name="active" id="active" v-model="form.active">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <hr>

                            <h4>Livros</h4>

                            <ul class="list-group overflow-auto normal-listing p-2">
                                <!-- Checkbox para selecionar todos os livros -->
                                <li class="list-group-item p-3">
                                    <input class="form-check-input me-1" type="checkbox" id="selectAllBooks"
                                        v-model="selectAllBooks">
                                    <label class="form-check-label stretched-link ms-3 fs-13" for="selectAllBooks"
                                        style="cursor: pointer;">Selecionar todos os livros</label>
                                </li>
                                <!-- Lista de livros -->
                                <li class="list-group-item p-3" v-for="(book, index) in books" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="book.id"
                                        :id="'checkBoxBook' + index" v-model="form.books">
                                    <label class="form-check-label stretched-link ms-3 fs-13" :for="'checkBoxBook' + index"
                                        style="cursor: pointer;">{{ book.name }}</label>
                                </li>
                            </ul>

                            <h4>Categorias</h4>

                            <ul class="list-group overflow-auto normal-listing p-2">
                                <li class="list-group-item p-3" v-for="(category, index) in categories" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                        :id="'checkBoxCategory' + index" v-model="form.categories">
                                    <label class="form-check-label stretched-link ms-3 fs-13"
                                        :for="'checkBoxCategory' + index" style="cursor: pointer;">{{ category.name
                                        }}</label>
                                </li>
                            </ul>

                        </div>

                        <div class="row d-flex justify-content-between mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#promotion-lear" role="button" aria-controls="order-lear">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success mt-3 mt-sm-0" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar promoção
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

