<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    store: Object,
});

const form = useForm({
    phone_number: props.store.phone_number,
    mobile_number: props.store.mobile_number,
    address: props.store.address,
    email: props.store.email
});

const update = () => form.put('/dashboard/store');
</script>

<template>
    <Dashboard page_title="Atualizar dados da loja">
        <Breadcrumb currentPageName="Atualizar dados da loja" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="update">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados da loja</h4>
                        </div>
                        <hr>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="phone_number" class="form-label">Telefone da loja</label>
                                <input type="text" autocomplete="off" class="form-control" id="phone_number"
                                    placeholder="Rhema" v-model="form.phone_number" required>
                                <small class="text-danger mt-3" v-if="form.errors.phone_number">{{ form.errors.phone_number
                                }}</small>
                            </div>
                            <div class="col-md-6">
                                <label for="mobile_number" class="form-label">Celular da loja</label>
                                <input type="text" autocomplete="off" class="form-control" id="mobile_number"
                                    placeholder="Rhema" v-model="form.mobile_number" required>
                                <small class="text-danger mt-3" v-if="form.errors.mobile_number">{{
                                    form.errors.mobile_number
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" autocomplete="off" class="form-control" id="email" placeholder="@"
                                    v-model="form.email" required>
                                <small class="text-danger mt-3" v-if="form.errors.email">{{ form.errors.email
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="address" class="form-label">Endereço</label>
                                <input type="text" autocomplete="off" class="form-control" id="address" placeholder="Rhema"
                                    v-model="form.address" required>
                                <small class="text-danger mt-3" v-if="form.errors.address">{{ form.errors.address
                                }}</small>
                            </div>

                            <div class="col-12 text-end">
                                <button class="btn btn-success mt-3 mt-sm-0" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Atualizar dados
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

