import { library } from "@fortawesome/fontawesome-svg-core";

import { 
  faEye, 
  faScrewdriverWrench,
  faChartColumn,
  faAt,
  faCirclePlus,
  faBarcode,
  faCircleExclamation,
  faPenToSquare, 
  faBoxArchive, 
  faFile, 
  faBarsStaggered, 
  faPlus, 
  faMoneyBillWave,
  faBook,
  faAngleDown,
  faUsers,
  faCircleCheck,
  faEllipsisVertical,
  faTrashCan,
  faRightFromBracket,
  faBasketShopping,
  faMagnifyingGlass,
  faCopyright,
  faLayerGroup,
  faTruckMoving,
  faTags,
  faGripVertical,
  faListCheck,
  faEyeSlash,
  faAddressBook,
  faSliders,
  faCreditCard,
  faKey,
  faUserTag,
  faUserShield,
  faShop,
  faArrowsRotate,
  faArrowUpRightFromSquare,
  faHouseCircleCheck,
  faUserGroup,
  faBars,
  faPhone,
  faBan,
  faEnvelope,
  faPercent,
  faAngleRight,
  faCircleInfo,
  faFilter,
  faBookmark,
  faFilePdf,
  faFileExcel,
  faBagShopping,
  faMapLocationDot
} from "@fortawesome/free-solid-svg-icons";

import { 
  faFacebook, 
  faYoutube, 
  faSpotify,
  faInstagram, 
  faWhatsapp 
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faSpotify,
  faCirclePlus,
  faAt,
  faAngleRight,
  faFilePdf,
  faFileExcel,
  faBookmark,
  faBarcode,
  faArrowsRotate,
  faWhatsapp,
  faFilter,
  faFacebook,
  faYoutube,
  faPhone,
  faBagShopping,
  faEnvelope,
  faInstagram,
  faCopyright,
  faMapLocationDot,
  faGripVertical,
  faScrewdriverWrench,
  faChartColumn,
  faTruckMoving,
  faListCheck,
  faCircleExclamation,
  faHouseCircleCheck,
  faShop,
  faKey,
  faCircleCheck,
  faCircleInfo,
  faEyeSlash,
  faBars,
  faEye, 
  faBan,
  faUserTag,
  faUserShield,
  faPenToSquare, 
  faBoxArchive, 
  faFile, 
  faAngleDown,
  faBarsStaggered, 
  faPlus, 
  faTrashCan, 
  faMoneyBillWave, 
  faBook, 
  faUsers, 
  faUserGroup,
  faEllipsisVertical,
  faRightFromBracket,
  faBasketShopping,
  faMagnifyingGlass,
  faSliders,
  faTags,
  faAddressBook,
  faLayerGroup,
  faCreditCard,
  faPercent,
  faArrowUpRightFromSquare
);