<template>
    <Layout :page_title="book.name">
        <div class="col-11 mx-auto">
            <div class="row p-0" id="show-book">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb font-semibold">
                        <li class="breadcrumb-item fs-13">
                            <Link href="/">Início</Link>
                        </li>
                        <li class="breadcrumb-item active fs-13" aria-current="page">{{ book.name }}</li>
                    </ol>
                </nav>
                
                <div class="col-lg-6 p-sm-2 order-md-1 order-2">
                    <p class="border-start border-5 border-danger font-bold rounded-1 p-3 bg-danger bg-opacity-10 fs-14 mb-3"
                        v-if="!book.quantity">
                        Esgotado: Este livro não está mais disponível em estoque
                    </p>
                    <p class="border-start border-5 border-warning font-bold rounded-1 p-3 bg-warning bg-opacity-10 fs-14 mb-3"
                        v-if="book.current_promotion && book.quantity">
                        Livro com {{ book.current_promotion.discount_percentage }}% de desconto
                    </p>

                    <h4 class="text-uppercase font-bold m-0">{{ book.name }}</h4><br>
                    <h5 class="font-bold" v-if="!book.current_promotion">{{ convertToCurrencyFormat(book.original_price) }}
                    </h5>
                    <h5 class="font-bold text-secondary mb-3" v-else>De <span class="text-decoration-line-through">
                            {{ convertToCurrencyFormat(book.original_price) }}</span> por {{
                                convertToCurrencyFormat(book.current_promotion.discounted_price) }}
                    </h5>

                    <form class="m-0" v-if="book.description">
                        <fieldset disabled>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Sinopse</label>
                                <textarea v-model="book.description" class="form-control" id="exampleFormControlTextarea1"
                                    rows="15"></textarea>
                            </div>
                        </fieldset>
                        <template v-if="categories.length > 0">
                            <hr>
                            <div class="mt-3">
                                <Link :href="`/temas/${category.clean_name}`"
                                    class="btn btn-warning mb-3 rounded-3 font-bold text-dark d-inline-block me-2 p-2"
                                    v-for="(category, index) in categories" :key="index" style="font-size: 10px;">
                                {{ category.name }}
                                </Link>
                            </div>
                        </template>
                    </form>

                    <div class="row mt-sm-3 d-flex align-items-end">
                        <div class="col-12 col-sm-4">
                            <div class="row" id="author-book">
                                <div class="col" v-for="(author, index) in authors" :key="index">
                                    <Link :href="`/autores/${author.clean_name}`">
                                    <img class="d-block mx-auto" :src="`/storage/${author.profile_picture_url}`" alt="" />
                                    <span class="text-center d-block mx-auto font-bold mt-2 fs-13">{{ author.name }}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3"><strong>Páginas:</strong> {{ book.total_pages == '0' ? '-' : book.total_pages }}</div>
                        <div class="col-12 col-sm-5"><strong>ISBN:</strong> {{ book.isbn }}</div>
                    </div>

                </div>

                <div class="col-12 col-lg-6 order-md-2 order-1 mb-4">
                    <a href="#" aria-label="Slavar como favorito" @click="saveAsFavorite(book)" class="float-end">
                        <font-awesome-icon class="fs-3" :class="`${bookIsFavorited ? 'text-warning' : 'text-secondary'}`" :icon="['fas', 'bookmark']" />
                    </a>
                    <img :src="`/storage/${book.cover_image_url}`" class="mx-auto d-block book-image mt-4 mt-sm-0" alt="" />
                </div>
            </div>
        </div>

        <div class="bg-dark mt-5 p-4" v-if="book.presentation_youtube_url">
            <h5 class="text-uppercase font-bold text-center text-white">Apresentação do Livro</h5>
            <div class="col-md-8 mx-auto col-12">
                <div class="my-4 d-flex justify-content-center">
                    <iframe class="rounded-4 youtube-video" :src="convertYouTubeLink(book.presentation_youtube_url)" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="col col-lg-12 col-xxl-8 mx-auto mt-5" id="sections" v-if="related_books.length">
            <section class="mb-sm-4">
                <h2 class="d-block text-center fw-bold mb-sm-3 title-with-arrows">Livros relacionados</h2>
                <Carousel :id="`section-related`" :data="related_books" />
            </section>
        </div>
    </Layout>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Link } from '@inertiajs/vue3';

import Layout from '@/Pages/Store/Layouts/Layout.vue';
import Carousel from '@/Pages/Store/Shared/Carousel.vue';

const props = defineProps({
    book: Object,
    related_books: Object,
    authors: Object,
    categories: Object
});

const bookIsFavorited = ref(false);

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();

const checkIfBookExists = (clean_name) => {
    let alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    let convertToArray = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];

    let exist = convertToArray.some((favoriteBook) => favoriteBook.clean_name === clean_name);

    if (exist) {
        bookIsFavorited.value = true;
    } else {
        bookIsFavorited.value = false;
    }
};

const saveAsFavorite = (book) => {
    let alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    let convertToArray = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];

    const index = convertToArray.findIndex((favoriteBook) => favoriteBook.clean_name === book.clean_name);

    if (index !== -1) {
        convertToArray.splice(index, 1);
        bookIsFavorited.value = false;
    } else {
        convertToArray.unshift(book);
        bookIsFavorited.value = true;
    }

    localStorage.setItem('verboshop_favorites', JSON.stringify(convertToArray));
};

onMounted(() => {
    checkIfBookExists(props.book.clean_name);
});

const convertYouTubeLink = (link) => {
    if (link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];

        return link;;
    }
}
</script>