<script setup>
import { Link, router } from '@inertiajs/vue3';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import LineChart from '@/Pages/Dashboard/Graphics/LineChart.vue';
import BarChart from '@/Pages/Dashboard/Graphics/BarChart.vue';

const props = defineProps({
    sales: Object,
    book_stock: Number,
    total_clients: Number,
    total_sales_today: Number,
    pending_books: String,
    users: Object,
    allBookSales: Array,
    differenceSalesOrders: Object,
    bestSellingBooks: Object,
    booksSoldByPaymentMethod: Array,
    slugLastSevenDays: Array,
    totalSalesLastSevenDays: Array
});

const { convertToCurrencyFormat } = usePriceFormatter();

const accessSale = (sale_id) => router.get(`/dashboard/sales/${sale_id}`);
const accessBook = (book_id) => router.get(`/dashboard/books/${book_id}`);

const isCurrentDate = (dateSale) => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return dateSale.split(" ")[0] === formattedDate;
}

const formatDate = (date) => {
    const parts = date.split(' ');
    const datePart = parts[0];
    const formattedDate = datePart.substr(0, 5);

    return formattedDate;
}
</script>

<template>
    <Dashboard page_title="Visão geral">
        <div class="mt-2 mx-0" id="home">
            <h3 class="font-bold mb-3">Visão geral</h3>
            <div class="row" id="main-cards">
                <div class="col-sm-3 col-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Vendas de hoje</h5>
                            <h4 class="font-bold mb-lg-3">{{ convertToCurrencyFormat(total_sales_today) }}</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/sales">Ver vendas</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0"><span
                                        class="px-3 py-2 bg-success bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'money-bill-wave']" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Estoque atual</h5>
                            <h4 class="font-bold mb-lg-3">{{ book_stock }} livros</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/books">Ver livros</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0"><span
                                        class="px-3 py-2 bg-primary bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'book']" /></span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Pedidos</h5>
                            <h4 class="font-bold mb-lg-3">{{ pending_books }} livros</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/orders">Ver pedidos</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0"><span
                                        class="px-3 py-2 bg-warning bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'box-archive']" />
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Clientes</h5>
                            <h4 class="font-bold mb-lg-3">{{ total_clients }} clientes</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/clients">Ver clientes</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0"><span
                                        class="px-3 py-2 bg-danger bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'users']" /></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-none d-sm-block">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="card p-3 graphics">
                            <LineChart 
                                :labels="slugLastSevenDays" 
                                :data="totalSalesLastSevenDays" 
                                title="Vendas dos últimos 7 dias"
                                unitMeasure="Venda(s)"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card p-3 graphics">
                            <BarChart 
                                :labels="booksSoldByPaymentMethod['labels']" 
                                :data="booksSoldByPaymentMethod['totals']" 
                                title="Vendas por gateway"
                                unitMeasure="Venda(s)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="card overflow-auto" id="last-sales">
                                <div class="card-header d-flex align-items-center justify-content-between bg-white">
                                    <span class="font-bold text-uppercase">Últimas vendas</span>
                                    <div class="dropdown d-inline">
                                        <a class="btn btn-light btn-sm" href="#" role="button" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li>
                                                <Link href="/dashboard/sales" class="dropdown-item fs-13"><font-awesome-icon
                                                    class="me-2" :icon="['fas', 'basket-shopping']" /> Todas as vendas
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/dashboard/sales/create" class="dropdown-item fs-13">
                                                <font-awesome-icon class="me-2" :icon="['fas', 'plus']" /> Nova venda</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body py-0">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Cliente</th>
                                                    <th class="text-center" scope="col">Pagamento</th>
                                                    <th class="text-center" scope="col">Vendedor</th>
                                                    <th class="text-center" scope="col">Valor</th>
                                                    <th class="text-center" scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(sale, index) in sales.data" :key="index"
                                                    @click="accessSale(sale.id)">
                                                    <td># {{ sale.id }}</td>
                                                    <td>{{ sale.client_name }}</td>
                                                    <td class="text-center">{{ sale.payment_method }}</td>
                                                    <td class="text-center">
                                                      
                                                        <img class="rounded-circle me-2" :src="sale.user_avatar"
                                                            width="30" alt="">
                                                            {{ sale.user_name }}
                                                       
                                                    </td>
                                                    <td class="text-center">{{
                                                        convertToCurrencyFormat(sale.total_sale_price) }}
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="isCurrentDate(sale.created_at)"
                                                            class="badge text-bg-primary p-2">Hoje</span>
                                                        <span v-else>{{ formatDate(sale.created_at) }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-3 d-flex align-items-center">
                <div class="col-lg-6 col-12 mb-2">
                    <div class="card overflow-auto" id="last-sales">
                        <div class="card-header d-flex align-items-center justify-content-between p-3 bg-white">
                            <span class="font-bold text-uppercase">Livros mais vendidos</span>
                        </div>
                        <div class="card-body py-0">
                            <div class="table-responsive">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Livro</th>
                                            <th class="text-center" scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(book, index) in bestSellingBooks" :key="index"
                                            @click="accessBook(book.id)">
                                            <td class="d-dlex align-items-center">
                                                <img style="width: 30px;" :src="`/storage/${book.cover_image_url}`" alt="">
                                                <span class="ms-3">{{ book.name }}</span>
                                            </td>
                                            <td class="text-center">{{ book.total_books_sold }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 px-4 px-sm-2">
                    <div class="row">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Promoções</h5>
                                <p class="card-text font-regular">Elabore promoções com o objetivo de reduzir o valor dos
                                    livros
                                </p>
                                <Link href="/dashboard/promotions" class="btn btn-success btn-sm text-white font-bold">Ver
                                promoções</Link>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Seções da loja</h5>
                                <p class="card-text font-regular">Organize as seções, atualize seus dados e ordene de
                                    maneira
                                    eficiente!</p>
                                <Link href="/dashboard/store-session" class="btn btn-primary btn-sm text-white font-bold">
                                Ver
                                seções</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </Dashboard>
</template>

