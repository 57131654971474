<script setup>
import { onMounted, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { Tooltip } from 'bootstrap';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import SimplifiedBookListing from '@/Pages/Dashboard/Shared/SimplifiedBookListing.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import Lear from './Components/Lear.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    session: Object,
    books: Object,
    categories: Object
});

onMounted(() => {
    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
    })
});
</script>

<template>
    <Dashboard :page_title="session.name">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="session.name" :linkList="[
                { previous_page_url: 'store-session', previous_page_name: 'Seções' }
            ]" />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6 d-flex align-items-center">
                        <img class="me-3" v-if="session.rhema" style="width: 35px;" src="/img/rhema_logo_colored.png" alt=""> <h4 class="font-bold m-0">{{ session.name }}</h4>
                    </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <button type="button" class="btn btn-success me-2" data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                :data-bs-title="`${session.visible ? 'Visível na loja' : 'Não visível na loja'}`">
                                <font-awesome-icon :icon="['fas', session.visible ? 'eye' : 'eye-slash']"
                                    class="text-white" />
                            </button>

                            <ButtonWithIcon v-if="permissions.edit_store_session" :href="`/dashboard/store-session/edit/${session.id}`"
                                background="primary" color="white" icon="pen-to-square" message="Editar seção" />
                        </div>
                    </div>

                    <template v-if="session.description">
                        <hr>
                        <p class="font-regular">
                            {{ session.description }}
                        </p>
                    </template>

                </div>
                <template v-if="categories.length > 0">
                    <hr>
                    <h5 class="font-bold">Categorias vinculadas ao seção</h5>

                    <div class="mt-2">
                        <Link :href="`/dashboard/categories/${category.id}`"
                            class="btn btn-warning rounded-pill mb-3 fs-12 font-bold text-dark d-inline-block me-2 p-2"
                            v-for="(category, index) in categories" :key="index">
                        {{ category.name }}
                        </Link>
                    </div>
                </template>
                <SimplifiedBookListing title="Livros vínculados a esta sessão" :books="books" />
            </div>
            <Lear />
        </div>
        <div class="lear-button">
            <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas" href="#section-lear"
                role="button" aria-controls="section-lear">
                Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
            </a>
        </div>
    </Dashboard>
</template>

