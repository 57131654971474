<template>
    <div>
        <Line ref="chartRef" :data="chartData" :options="chartOptions" />
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
} from 'chart.js';
import { Line } from 'vue-chartjs';

const props = defineProps({
    labels: Array,
    barData: Array,
    lineData: Array
});

ChartJS.register(
    CategoryScale, 
    LinearScale, 
    BarElement, 
    LineElement, 
    Title, 
    Tooltip, 
    Legend, 
    PointElement
);

const chartData = reactive({
    labels: props.labels,
    datasets: [
        {
            type: 'line',
            label: 'Line Dataset',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 2,
            fill: false,
            data: props.lineData,
            order: 1
        },
        {
            type: 'bar',
            label: 'Bar Dataset 1',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            data: props.barData,
            order: 2
        },
    ],
});

const chartOptions = reactive({
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    }
});
</script>
