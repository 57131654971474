<script setup>
import { ref, onMounted, computed } from "vue";
import { router, useForm, usePage } from '@inertiajs/vue3';

import usePreviewImage from '@/Composables/PreviewImage.vue';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    client: Object,
    errors: Object,
});

const formEditClient = useForm({
    name: props.client.name,
    email: props.client.email,
    type: props.client.type,
    phone_number: props.client.phone_number,
    profile_image_url: null,
    _method: 'put',
});

const clientEditModalCloseButton = ref(null);

const update = () => formEditClient.post(`/dashboard/clients/${props.client.id}`);
const destroy = () => {
    clientEditModalCloseButton.value.click();
    router.delete(`/dashboard/clients/${props.client.id}`);
};

onMounted(() => {
    imageUrl.value = props.client.avatar;
});
</script>

<template>
    <Dashboard :page_title="client.name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="client.name" :linkList="[
                { previous_page_url: 'clients', previous_page_name: 'Clientes' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do cliente</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon 
                                :href="`/dashboard/clients/${client.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />

                            <ButtonWithIcon v-if="permissions.destroy_client" 
                                href="#" 
                                background="danger" 
                                color="white"
                                icon="trash-can" 
                                :linkInertia="false" 
                                message="Deletar"
                                dataBsToggle="modal"
                                dataBsTarget="#clientEditModal"
                            />
                        </div>
                    </div>
                    <hr>
                    <form class="border-0 shadow-none" @submit.prevent="update">
                        <div class="row g-4">
                            <div class="col-5">
                                <label for="name" class="form-label">Nome do cliente</label>
                                <input type="text" id="name" autocomplete="off" class="form-control"
                                    v-model="formEditClient.name" required>
                                <small class="text-danger" v-if="formEditClient.errors.name">{{
                                    formEditClient.errors.name
                                }}</small>
                            </div>
                            <div class="col-4">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" id="email" autocomplete="off" class="form-control"
                                    v-model="formEditClient.email">
                                <small class="text-danger" v-if="formEditClient.errors.email">{{
                                    formEditClient.errors.email
                                }}</small>
                            </div>
                            <div class="col-3">
                                <label for="phone_number" class="form-label">Telefone de contato</label>
                                <input type="text" id="phone_number" autocomplete="off" class="form-control"
                                    v-model="formEditClient.phone_number">
                                <small class="text-danger" v-if="formEditClient.errors.phone_number">{{
                                    formEditClient.errors.phone_number
                                }}</small>
                            </div>
                            <div class="col-md-8">
                                <label for="formFile" class="form-label">Selecione uma nova foto - Tamanho máximo permitido:
                                    2mb</label>
                                <input class="form-control"
                                    @input="formEditClient.profile_image_url = $event.target.files[0]" type="file"
                                    id="formFile" accept="image/png,image/jpeg" @change="previewImage">
                                <small class="text-danger mt-3" v-if="formEditClient.errors.profile_image_url">{{
                                    formEditClient.errors.profile_image_url
                                }}</small>

                            </div>
                            <div class="col-md-3 col-md-4">
                                <label for="type" class="form-label">Tipo de cliente</label>
                                <select class="form-select" name="type" id="type" v-model="formEditClient.type">
                                    <option value="COMUM">Comum</option>
                                    <option value="PASTOR">Pastor</option>
                                    <option value="ALUNO_RHEMA">Aluno Rhema</option>
                                </select>
                            </div>
                            <div class="col-md-11 mx-auto mt-4">
                                <img class="d-block mx-auto preview-image border border-5 rounded-circle"
                                    v-if="imageUrl != '/storage/null'" :src="imageUrl" />
                            </div>
                        </div>
                        <button class="btn btn-success float-end my-3" type="submit"
                            :disabled="formEditClient.processing || imageSizeInMB > 2">
                            <span v-if="formEditClient.processing" class="spinner-border spinner-border-sm me-2"
                                role="status" aria-hidden="true"></span>
                            Atualizar dados
                        </button>
                    </form>

                    <ConfirmationModal modal_id="clientEditModal" modal_title="Confirmação de remoção do cliente">
                        <template v-slot:modal-text>
                            Você tem certeza que deseja excluir o cliente {{ client.name }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="clientEditModalCloseButton" class="btn btn-secondary text-white"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="destroy" type="button" class="btn btn-danger text-white">Deletar cliente
                            </button>
                        </template>
                    </ConfirmationModal>

                </div>
            </div>
        </div>
    </Dashboard>
</template>

