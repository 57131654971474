<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const form = useForm({ observation: null });

const store = () => form.post('/dashboard/orders');
</script>

<template>
    <Dashboard page_title="Criar pedido">
        <Breadcrumb currentPageName="Criar pedido" />
        <PageNavigationHeader pageTitle="Criar pedido" baseRoute="orders" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-11 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <p
                                class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10">
                                <font-awesome-icon class="me-3" :icon="['fas', 'screwdriver-wrench']" />
                                Faça pedidos para incluir novos livros na loja
                            </p>
                            <div class="col-md-12 text-end">
                                <button class="btn btn-success float-end my-2" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Iniciar contrução 
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

