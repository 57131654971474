<template>
    <div class="container-fluid p-0 m-0" id="book-store" :class="{ 'rhema-day': rhemaDay }">
        <div id="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <Head :title="page_title" />
        <Navbar />
        <div class="col-lg-12 mx-auto col-xxl-9 mx-auto" id="main-container">
            <div class="dividing-line">
                &nbsp;
            </div>
            <slot></slot>
        </div>
        <div class="modal fade" id="bookSearchModal" tabindex="-1" aria-labelledby="bookSearchModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="bookSearchModalLabel">Buscar livros</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body bg-light p-3">
                        <form action="">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Nome</label>
                                <input type="text" ref="modalName" autocomplete="off" class="form-control"
                                    id="exampleFormControlInput1" v-model="name" @input="compositionUpdate"
                                    placeholder="Digite o nome do livro">
                            </div>
                        </form>
                        <ul class="list-group">
                            <a href="#" @click="viewBook(`/livros/${book.clean_name}`)" v-for="(book, index) in books" :key="index"              
                                class="list-group-item mt-2 d-flex align-items-center justify-content-between bg-white rounded-3 border">
                            <div class="d-flex">
                                <img :src="`/storage/${book.cover_image_url}`" alt="" style="width: 50px;">
                                <div class="fs-12 font-semibold">
                                    <span>{{ book.name }}</span><br>
                                    <span>{{ convertToCurrencyFormat(book.original_price) }}</span>
                                </div>
                            </div>
                            <font-awesome-icon :icon="['fas', 'angle-right']" />
                        </a>
                        </ul>

                        <div class="d-flex justify-content-center" v-if="processing">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else>
                            <div class="d-block text-center my-3" v-if="Object.values(books).length > 0">
                                <Link :href="`/livros/?name=${name}`"
                                    class="btn btn-warning fs-13 text-uppercase rounded-2 text-center font-semibold text-dark"
                                    @click.native="modal.click()">Ver todos os
                                resultados</Link>
                            </div>
                            <div v-else-if="Object.values(books).length == 0 && searchStarted">
                                <div>
                                    Nenhum livro foi encontrado
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button data-bs-dismiss="modal" type="button" class="btn btn-secondary text-white fs-14 ms-3">
                            Fechar 
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Favorites />
        <Footer /> 
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount } from 'vue';
import { Head, Link, router, useForm } from '@inertiajs/vue3';
import { Tooltip } from 'bootstrap';

import Navbar from './Navbar.vue';
import Footer from './Footer.vue';
import Favorites from './Favorites.vue';

import useAxios from "@/Services/Axios.vue";
import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const props = defineProps({ page_title: String, rhemaDay: String });

const { instance: axios } = useAxios();
const { convertToCurrencyFormat } = usePriceFormatter();

const modal = ref(null);
const modalElement = ref(null);

const name = ref(null);
const modalName = ref(null);
const books = ref([]);
const searchStarted = ref(false);
const processing = ref(false);
const tooltips = [];

const compositionUpdate = (event) => {
    name.value = event.target.value;
};

const seacher = () => {
    searchStarted.value = true;
    processing.value = true;
    axios.get(`/seache?name=${name.value}`)
        .then((response) => {
            books.value = response.data.books;
        }).finally(() => {
            processing.value = false;
        })
}

watch(name, (value) => {
    seacher();
});

const form = useForm({})

const viewBook = (url) => {
    form.get(url, {
        onBefore: (page) => modal.value.click()
    });
}

const rhemaDay = computed(() => {
    const currentDayOfWeek = new Date().getDay(); 

    if ([1, 3, 5].includes(currentDayOfWeek)) {
        return true;
    }

    return false;
});

onMounted(() => {
    modalElement.value = document.getElementById('bookSearchModal');

    modalElement.value.addEventListener('shown.bs.modal', () => {
        modalName.value.focus()
        modalName.value.click()
    });

    const tooltipElements = document.querySelectorAll("[data-bs-toggle='tooltip']");
    tooltipElements.forEach(element => {
        tooltips.push(new Tooltip(element, {
            trigger: 'hover'
        }));
    });
});

onBeforeUnmount(() => {
    tooltips.forEach(tooltip => {
        tooltip.dispose();
    });
});
</script>