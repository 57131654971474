<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({ data: Object, reference_date: String });

const formatLabel = (label) => {
  const labels = {
    '&laquo; Previous': 'Voltar',
    'Next &raquo;': 'Próximo'
  };
  return labels[label] || label;
};

</script>

<template>
  <nav class="mt-4 d-flex justify-content-sm-center" v-if="data.links.length > 3">
    <ul class="pagination">
      <li class="page-item" v-for="(link, index) in data.links" :key="index">
        <Link class="page-link font-semibold" :class="{ 'active': link.active, 'disabled': !link.url }"
          :only="[`${reference_date}`]" preserve-state preserve-scroll :href="link.url || '#'"
          v-html="formatLabel(link.label)">
        </Link>
      </li>
    </ul>
  </nav>
</template>

