<script setup>
import { computed } from "vue";
import { Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    paymentMethods: Object
});
</script>

<template>
    <Dashboard page_title="Listagem de métodos de pagamento">
        <Breadcrumb currentPageName="Métodos de pagamento" />
        <PageNavigationHeader pageTitle="Métodos" baseRoute="payment-methods" :showAdd="permissions.create_payment_method" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <div class="table-responsive-sm col-11 mx-auto p-3">
                        <table class="table table-hover" id="table-payment-methods">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(method, index) in paymentMethods" :key="index">
                                    <td scope="row">{{ parseInt(index) + 1 }}</td>
                                    <td>{{ method.name }}</td>
                                    <td>{{ method.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/payment-methods/${method.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_payment_method" :href="`/dashboard/payment-methods/edit/${method.id}`"
                                            class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

