<script setup>
import { reactive, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    sales: Object, 
    clients: Object, 
    users: Object
});

const searchForm = reactive({
    client_id: '',
    status: '',
    start_date: '',
    end_date: '',
    user_id: ''
});

const { convertToCurrencyFormat } = usePriceFormatter();

const seacher = () => router.get('/dashboard/sales', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de vendas">
        <Breadcrumb currentPageName="Listagem de vendas" />
        <PageNavigationHeader pageTitle="Vendas" baseRoute="sales" :showAdd="permissions.create_sale" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto py-3">
                        <div class="d-flex justify-content-between align-items-center mt-2 row">
                            <div class="font-bold col-12 col-sm-6">
                                Baixar vendas
                            </div>
                            <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                                <ButtonWithIcon 
                                    :href="`/dashboard/sales/pdf/export?client_id=${searchForm.client_id}&status=${searchForm.status}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&user_id=${searchForm.user_id}`" 
                                    background="danger" 
                                    color="white"
                                    icon="file-pdf" 
                                    :linkInertia="false" 
                                    message="PDF" 
                                    additionalStyles="me-2"
                                />

                                <ButtonWithIcon 
                                :href="`/dashboard/sales/xlxs/export?client_id=${searchForm.client_id}&status=${searchForm.status}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&user_id=${searchForm.user_id}`" 
                                    background="success" 
                                    color="white"
                                    icon="file-excel" 
                                    :linkInertia="false" 
                                    message="Excel" 
                                />
                            </div>
                        </div>
                        <hr>
                        <div class="row g-3">
                            <div class="col-sm-4">
                                <label for="clientId" class="form-label">Cliente</label>
                                <select class="form-select" id="clientId" v-model="searchForm.client_id"
                                    aria-label="Default select example">
                                    <option value="">Todos os cliente</option>
                                    <option v-for="(client, index) in clients" :key="index" :value="client.id">{{
                                        client.name }}</option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <label for="status" class="form-label">Status da venda</label>
                                <select class="form-select" id="status" v-model="searchForm.status"
                                    aria-label="Default select example">
                                    <option selected value="">Todos os status</option>
                                    <option value="CONCRETIZADA">Concretizada</option>
                                    <option value="CANCELADA">Cancelada</option>
                                </select>
                            </div>
                            <div class="col-6 col-sm-4">
                                <label for="startDate" class="form-label">Data de início</label>
                                <input type="date" id="startDate" class="form-control" v-model="searchForm.start_date" name="">
                            </div>
                            <div class="col-6 col-sm-4">
                                <label for="endDate" class="form-label">Data de fim</label>
                                <input type="date" id="endDate" class="form-control" v-model="searchForm.end_date" name="">
                            </div>
                            <div class="col-sm-4">
                                <label for="userId" class="form-label">Vendedor</label>
                                <select class="form-select" id="userId" v-model="searchForm.user_id"
                                    aria-label="Default select example">
                                    <option value="">Qualquer vendedor</option>
                                    <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary float-end text-white mt-3 mt-sm-0" @click="seacher"><font-awesome-icon class="me-2" :icon="['fas', 'magnifying-glass']" /> Buscar</a>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive col-11 mx-auto">
                        <table class="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th class="text-center" scope="col">Id</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col" class="text-center">Valor</th>
                                    <th scope="col" class="text-center">Vendedor</th>
                                    <th scope="col" class="text-center">Q.livros</th>
                                    <th scope="col" class="text-center">Data</th>
                                    <th scope="col" class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="sales.meta.total > 0">
                                <tr v-for="(sale, index) in sales.data" :key="index"
                                    :class="{ 'bg-danger bg-opacity-10': sale.status == 'CANCELADA' }">
                                    <td class="text-center" scope="row">#{{ sale.id }}</td>
                                    <td>{{ sale.client_name }}</td>
                                    <td class="text-center">{{ convertToCurrencyFormat(sale.total_sale_price) }}</td>
                                    <td class="text-center">
                                        <img class="rounded-circle" :src="sale.user_avatar" width="30" alt="">
                                    </td>
                                    <td class="text-center">{{ sale.total_books }}</td>
                                    <td class="text-center">{{ sale.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/sales/${sale.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhuma venda encontrada
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="sales.meta.total > 0">
                                    <td class="text-end" colspan="7">
                                        <span class="fs-14 font-semibold">{{ sales.meta.total }} vendas
                                            encontradas
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="sales.meta" reference_date="sales" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

