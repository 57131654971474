<script setup>
import { onMounted, computed } from "vue";
import { useForm, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

import { vMaska } from "maska";
import { VMoney } from 'v-money';

import usePreviewImage from '@/Composables/PreviewImage.vue';
import useMoney from '@/Composables/Money.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const props = defineProps({
    allCategories: Object,
    allAuthors: Object,
    book: Object,
    bookCategories: Object,
    bookAuthors: Object,
});

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();
const { money } = useMoney();

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const form = useForm({
    _method: 'put',
    name: props.book.name,
    isbn: props.book.isbn,
    quantity: props.book.quantity,
    total_pages: props.book.total_pages,
    original_price: props.book.original_price,
    description: props.book.description,
    presentation_youtube_url: props.book.presentation_youtube_url,
    spotify_link_url: props.book.spotify_link_url,
    visible: props.book.visible ? 1 : 0,
    cover_image_url: '',
    categories: '',
    authors: ''
});

const { convertDecimalFormat } = usePriceFormatter();

const update = () => {
    form.transform((data) => ({
        ...data,
        original_price: convertDecimalFormat(data.original_price),
    })).post(`/dashboard/books/${props.book.id}`, form);
};

onMounted(() => {
    imageUrl.value = `/storage/${props.book.cover_image_url}`;
    form.categories = props.bookCategories.map((category) => category.id);
    form.authors = props.bookAuthors.map((author) => author.id);
});
</script>

<template>
    <Dashboard :page_title="book.name">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="book.name" :linkList="[
                { previous_page_url: 'books', previous_page_name: 'Livros' }
            ]" />

            <div class="col-lg-12 bg-white p-4 shadow-sm rounded-3 border">
                <div id="edit-book">

                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do livro</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon 
                                :href="`/dashboard/books/${book.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />
                        </div>
                    </div>
                    <hr>
                    <div class="row p-3">
                        <form class="border-0 shadow-none" id="book-edit-accordion" @submit.prevent="update">
                            <div class="row g-3 mb-3">
                                <div class="col-md-8">
                                    <label for="name" class="form-label">Nome do livro</label>
                                    <input type="text" class="form-control" autocomplete="off" id="name" name="name"
                                        v-model="form.name" required>
                                    <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                    }}</small>
                                </div>
                                <div class="col-md-4">
                                    <label for="isbn" class="form-label">ISBN</label>
                                    <input type="text" class="form-control" autocomplete="off" id="isbn" name="isbn" v-model="form.isbn" v-maska data-maska="###-##-####-###-#" required>
                                    <small class="text-danger mt-3" v-if="form.errors.isbn">{{ form.errors.isbn
                                    }}</small>
                                </div>
                                <div class="col-md-3 col-6">
                                    <label for="total_pages" class="form-label">Páginas</label>
                                    <input type="text" autocomplete="off" class="form-control" id="total_pages" name="total_pages"
                                        v-model="form.total_pages" required>
                                    <small class="text-danger mt-3" v-if="form.errors.total_pages">{{
                                        form.errors.total_pages
                                    }}</small>
                                </div>

                                <div class="col-md-3 col-6">
                                    <label for="quantity" class="form-label">Quantidade</label>
                                    <input type="text" class="form-control" autocomplete="off" id="quantity" name="quantity" 
                                        v-model="form.quantity" :disabled="!permissions.edit_book_quantity" required>
                                    <small class="text-danger mt-3" v-if="form.errors.quantity">{{ form.errors.quantity
                                    }}</small>
                                </div>
                                <div class="col-md-3 col-6">
                                    <label for="original_price" class="form-label">Preço do
                                        livro</label>
                                    <input type="text" class="form-control" id="original_price" name="original_price"
                                        v-model.lazy="form.original_price" autocomplete="off" v-money="money" maxlength="7" required>
                                    <small class="text-danger mt-3" v-if="form.errors.original_price">{{
                                        form.errors.original_price
                                    }}</small>
                                </div>
                                <div class="col-md-3 col-6">
                                    <label for="visible" class="form-label">Vísivel</label>
                                    <select class="form-select" id="visible" name="visible" v-model="form.visible">
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="presentation_youtube_url" class="form-label">Link apresentação Youtube</label>
                                    <input type="text" class="form-control" autocomplete="off" id="presentation_youtube_url" name="presentation_youtube_url"
                                        v-model="form.presentation_youtube_url" placeholder="https://www.youtube.com/watch?verbodavida">
                                    <small class="text-danger mt-3" v-if="form.errors.presentation_youtube_url">{{
                                        form.errors.presentation_youtube_url
                                    }}</small>
                                </div>
                                <div class="col-md-6">
                                    <label for="spotify_link_url" class="form-label">Link Sportify</label>
                                    <input type="text" class="form-control" autocomplete="off" id="spotify_link_url" name="spotify_link_url"
                                        v-model="form.spotify_link_url" placeholder="https://open.spotify.com/intl-pt/album">
                                    <small class="text-danger mt-3" v-if="form.errors.spotify_link_url">{{
                                        form.errors.spotify_link_url
                                    }}</small>
                                </div>
                                <div class="col-md-12">
                                    <label for="description" class="form-label">Descrição</label>
                                    <textarea type="text" class="form-control" id="description" name="description"
                                        v-model="form.description" rows="10"></textarea>
                                </div>


                                <div class="col-lg-12 mt-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5>Categorias vinculadas</h5>
                                            <ul class="list-group overflow-auto category-listing mt-3">
                                                <li class="list-group-item p-3" v-for="(category, index) in allCategories"
                                                    :key="index">
                                                    <input class="form-check-input me-1" type="checkbox"
                                                        :value="category.id" :id="'checkBoxCategory' + category.id"
                                                        :checked="form.categories.includes(category.id) ? true : false"
                                                        v-model="form.categories">
                                                    <label class="form-check-label stretched-link ms-3"
                                                        :for="'checkBoxCategory' + category.id" style="cursor: pointer;">{{
                                                            category.name
                                                        }}</label>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-md-6 mt-3 mt-sm-0">
                                            <h5>Autores</h5>
                                            <ul class="list-group overflow-auto mt-3 author-listing">
                                                <li class="list-group-item p-3" v-for="(author, index) in allAuthors"
                                                    :key="index">
                                                    <input class="form-check-input me-1" type="checkbox" :value="author.id"
                                                        :id="'checkBoxAuthor' + author.id"
                                                        :checked="form.authors.includes(author.id) ? true : false"
                                                        v-model="form.authors">
                                                    <label class="form-check-label stretched-link ms-3"
                                                        :for="'checkBoxAuthor' + author.id" style="cursor: pointer;">{{
                                                            author.name
                                                        }}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="col-md-12">
                                        <label for="formFile" class="form-label">Selecione uma nova foto para a capa do
                                            livro - Tamanho máximo permitido: 2mb</label>
                                        <input class="form-control" @input="form.cover_image_url = $event.target.files[0]"
                                            type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage">

                                    </div>
                                    <div class="col-md-11 mx-auto mt-3">
                                        <img class="d-block mx-auto book-cover" v-if="imageUrl" :src="imageUrl" />
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-success float-end" type="submit"
                                :disabled="form.processing || imageSizeInMB > 2">
                                <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                    aria-hidden="true"></span>
                                Atualizar dados
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

