<script>
import { ref } from 'vue';

export default function useMessage() {

    const message = ref({
        description: '',
        type: ''
    });

    function setMessage(description, type) {
        message.value = {
            description,
            type
        };
    }

    return {
        message,
        setMessage
    }
}
</script>
  