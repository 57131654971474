<script setup>

import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import SimplifiedBookListing from '@/Pages/Dashboard/Shared/SimplifiedBookListing.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import AuthorImage from '@/Pages/Dashboard/Pages/Authors/Components/AuthorImage.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    author: Object,
    books: Object,
});
</script>

<template>
    <Dashboard :page_title="author.name">
        <div class="row mx-0" id="show-author">
            <Breadcrumb :currentPageName="author.name" :linkList="[
                { previous_page_url: 'authors', previous_page_name: 'Autores' }
            ]" />
            <div class="col-lg-12 bg-white p-4 rounded-3 border">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-6">
                        <h4 class="font-bold m-0">{{ author.name }}</h4>
                    </div>
                    <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                        <ButtonWithIcon v-if="permissions.edit_author"
                            :href="`/dashboard/authors/edit/${author.id}`" 
                            background="primary" 
                            color="white"
                            icon="pen-to-square" 
                            message="Editar cliente"
                        />
                    </div>
                </div>
                <hr>
                <AuthorImage v-if="author.profile_picture_url" :url="`/storage/${author.profile_picture_url}`" />
                <template v-if="author.personal_description">
                    <span class="font-bold">Resumo pessoal</span>
                    <p class="mt-3 text-justify">
                        {{ author.personal_description }}
                    </p>
                </template>
            </div>
            <SimplifiedBookListing :title="`livros disponíveis de ${author.name} para você explorar`" :books="books" />
        </div>
    </Dashboard>
</template>

