<template>
    <div class="card border-0 timeline">
        <div class="card-body px-5 border-0">
            <ul class="timeline-with-icons">
                <li class="timeline-item mb-4" v-for="(activity, index) in activitys" :key="index">
                    <span :class="getColorClass(activity.event)">
                        <font-awesome-icon class="text-white" :icon="['fas', getIcon(activity.event)]" />
                    </span>
                    <h5 class="timeline-title">{{ activity.details.title }}</h5>
                    <p class="text-muted mb-2 font-semibold fs-13">{{ formatDate(activity.created_at) }}</p>
                    <p class="text-muted d-flex alig-item-center">
                        <span class="me-3">
                            <img class="rounded-circle" width="25" :src="activity.user_avatar" alt="">
                        </span>
                        <span>{{ activity.details.description }}</span>
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script setup>
const props = defineProps({
    activitys: Object,
    title: String
});

const getColorClass = (event) => {
    const iconClasses = {
        updated: 'bg-info',
        created: 'bg-success',
        'pending-order': 'bg-primary',
        'finished-order': 'bg-success',
        'completed-sale': 'bg-success',
        'canceled-sale': 'bg-danger',
        'manual-quantity-change': 'bg-warning'
    };

    return `timeline-icon ${iconClasses[event]}`;
};

const getIcon = (event) => {
    const icons = {
        updated: 'pen-to-square',
        created: 'check-circle',
        'pending-order': 'box-archive',
        'finished-order': 'box-archive',
        'completed-sale': 'box-archive',
        'canceled-sale': 'ban',
        'manual-quantity-change': 'book'
    };

    return `${icons[event]}`;
};

const formatDate = (date) => {
    let formatted_date = date.split('T')
    let dates = formatted_date[0].split('-')

    return `${dates[2]}/${dates[1]}/${dates[0]}`
}
</script>