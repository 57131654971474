<script setup>
import { onMounted, ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';

const form = useForm({
    password: null,
    token: null,
    password_confirmation: null,
    email: null
});

const forgotPassword = () => form.post('/reset-password');

const extractTokenFromUrl = () => {
    const url = window.location.href;
    const partials = url.split("/reset-password/");
    form.token = partials[1] || ''
};

onMounted(() => {
    extractTokenFromUrl();
});
</script>

<template>
    <Head title="Definir nova senha" />
    <div class="vh-100 d-flex justify-content-center align-items-center bg-light">
        <div class="col-lg-6 col-xxl-4">
            <div class="card border shadow-sm p-5 rounded-3">
                <div class="row m-0">
                    <form @submit.prevent="forgotPassword">
                        <img class="mx-auto d-block mb-4" width="150" src="/img/verboshop-admin-logo.png" alt="">
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" placeholder="exemplo@gmail.com" class="form-control" name="email" id="email" v-model="form.email"
                                aria-describedby="emailHelp" autocomplete="on" required>
                            <small class="text-danger mt-3" v-if="form.errors.email">
                                {{ form.errors.email }}
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Nova senha</label>
                            <input type="password" class="form-control" name="password" id="password"
                                v-model="form.password" autocomplete="off" aria-describedby="passwordHelp" required>
                            <small class="text-danger mt-3" v-if="form.errors.password">
                                {{ form.errors.password }}
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="passwordConfirmationHelp" class="form-label">Confirmar nova senha</label>
                            <input type="password" class="form-control" autocomplete="off" name="password_confirmation"
                                id="passwordConfirmationHelp" v-model="form.password_confirmation"
                                aria-describedby="passwordConfirmationHelp" required>
                            <small class="text-danger mt-3" v-if="form.errors.password_confirmation">
                                {{ form.errors.password_confirmation }}
                            </small>
                        </div>

                        <button class="btn btn-primary w-100 font-bold text-white mb-4" type="submit"
                            :disabled="form.processing">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Redefinir senha
                        </button>

                        <div class="row">
                            <small class="text-center font-regular copyright">
                                Direitos reservados: VerboShop Paulo Afonso <font-awesome-icon class="ms-2" :icon="['fas', 'copyright']" />
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>