<script setup>
import { reactive, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import FormatBadges from '@/Pages/Dashboard/Shared/FormatBadges.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    orders: Object
});

const searchForm = reactive({
    status: '',
    start_date: '',
    end_date: ''
});

const { convertToCurrencyFormat } = usePriceFormatter();
const seacher = () => router.get('/dashboard/orders', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de pedidos">
        <Breadcrumb currentPageName="Listagem de pedidos" />
        <PageNavigationHeader pageTitle="Pedidos" baseRoute="orders" :showAdd="permissions.create_order" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto py-3">
                        <div class="row g-3">
                            <div class="col-sm-4">
                                <label for="status" class="form-label">Status do pedido</label>
                                <select class="form-select" id="status" v-model="searchForm.status">
                                    <option selected value="">Todos os status</option>
                                    <option value="PENDENTE">Pendente</option>
                                    <option value="APROVADO">Aprovado</option>
                                    <option value="FINALIZADO">Finalizado</option>
                                    <option value="CANCELADO">Cancelado</option>
                                </select>
                            </div>
                            <div class="col-6 col-sm-4">
                                <label for="startDate" class="form-label">Data de início</label>
                                <input type="date" id="startDate" class="form-control" v-model="searchForm.start_date"
                                    name="">
                            </div>
                            <div class="col-6 col-sm-4">
                                <label for="startDate" class="form-label">Data de fim</label>
                                <input type="date" id="startDate" class="form-control" v-model="searchForm.end_date"
                                    name="">
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary float-end text-white mt-4" @click="seacher">
                             <font-awesome-icon class="me-2" :icon="['fas', 'magnifying-glass']" /> Buscar
                            </a>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive col-11 mx-auto">
                        <table class="table table-hover" id="tableOrders">
                            <thead>
                                <tr>
                                    <th class="text-center" scope="col">ID</th>
                                    <th class="text-center" scope="col">Status</th>
                                    <th class="text-center" scope="col">Valor</th>
                                    <th class="text-center" scope="col">Q.Livros</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="orders.meta.total > 0">
                                <tr v-for="(order, index) in orders.data" :key="index">
                                    <td class="text-center">#{{ order.id }}</td>
                                    <td class="text-center">
                                        <FormatBadges :badge="order.status" />
                                    </td>
                                    <td class="text-center">{{ convertToCurrencyFormat(order.total_orders_price) }}</td>
                                    <td class="text-center">{{ order.total_books }}</td>
                                    <td class="text-center">{{ order.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/orders/${order.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_order" :href="`/dashboard/orders/edit/${order.id}`"
                                            class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="6" class="p-3 font-semibold">
                                    Nenhum pedido encontrado
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="orders.meta.total > 0">
                                    <td class="text-end" colspan="6">
                                        <span class="fs-14 font-semibold">{{ orders.meta.total }} pedidos
                                            encontrados
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="orders.meta" reference_date="orders" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

