<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import usePreviewImage from '@/Composables/PreviewImage.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const props = defineProps({ roles: Object });

const form = useForm({ 
    name: '', 
    email: '', 
    profile_image_url: '',
    role_id: props.roles[0].id 
});

const store = () => form.post('/dashboard/users');
</script>

<template>
    <Dashboard page_title="Criar membro">
        <Breadcrumb currentPageName="Criar membro" />
        <PageNavigationHeader pageTitle="Criar membro" baseRoute="users" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="name" class="form-label">Nome</label>
                                <input type="text" class="form-control" autocomplete="off" id="name" name="name" v-model="form.name"
                                    placeholder="Matheus" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-6">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" autocomplete="off" class="form-control" id="email" name="email" v-model="form.email"
                                    placeholder="matheus@gmail.com" required>
                                <small class="text-danger mt-3" v-if="form.errors.email">{{ form.errors.email
                                }}</small>
                            </div>
                            <div class="col-md-3">
                                <label for="role" class="form-label">Função</label>
                                <select class="form-select" name="role" v-model="form.role_id">
                                    <option v-for="(role, index) in roles" :key="index" :value="role.id">{{
                                        role.display_name }}</option>
                                </select>
                                <small class="text-danger mt-3" v-if="form.errors.role">{{ form.errors.role
                                }}</small>
                            </div>
                            <div class="col-md-9">
                                <label for="formFile" class="form-label">Selecione uma foto  - Tamanho máximo permitido: 2mb</label>
                                <input class="form-control" @input="form.profile_image_url = $event.target.files[0]"
                                    type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage" required>
                                <small class="text-danger mt-3" v-if="form.errors.profile_image_url">{{
                                    form.errors.profile_image_url
                                }}</small>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <img class="d-block mx-auto preview-image rounded-circle" v-if="imageUrl" :src="imageUrl" />
                            </div>

                            <div class="col-12 text-end">
                                <button class="btn btn-success float-end" type="submit"
                                    :disabled="form.processing || imageSizeInMB > 2">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar membro
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>


