<script setup>
import { ref, computed } from 'vue';
import { Link, router, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue'
import Lear from './Components/Lear.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const saleCancellationModalCloseButton = ref(null);

const props = defineProps({ 
    bookSales: Object, 
    sale: Object
});

const { convertToCurrencyFormat } = usePriceFormatter();

const cancellationSale = () => {
    router.post(`/dashboard/sales/cancel-sale/${props.sale.id}`);
    saleCancellationModalCloseButton.value.click();
};
</script>

<template>
    <Dashboard :page_title="`Venda nº ${sale.id}`">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="`Venda nº ${sale.id}`" :linkList="[
                { previous_page_url: 'sales', previous_page_name: 'Vendas' }
            ]" />
            <div class="col-lg-12 bg-white p-4 rounded-3 border">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-5">
                        <h4 class="font-bold m-0">Venda nº {{ sale.id }}</h4>
                    </div>
                    <div class="col-sm-7 col-12 mt-3 mt-sm-0 text-end" v-if="sale.status != 'CANCELADA'">
                        <ButtonWithIcon 
                            v-if="permissions.cancel_sale"
                            href="#" 
                            background="danger" 
                            color="white"
                            icon="ban" 
                            :linkInertia="false" 
                            message="Cancelar venda"
                            dataBsToggle="modal"
                            dataBsTarget="#saleCancellationModal"
                            additionalStyles="me-2"
                        />

                        <ButtonWithIcon v-if="sale.status == 'CONCRETIZADA' && permissions.edit_sale"
                            :href="`/dashboard/book-sales/${sale.id}/create`" 
                            background="success" 
                            color="white"
                            icon="plus" 
                            message="Nova venda"
                        />
                    </div>
                </div>
                <hr>
                <div class="alert alert-light border m-0 mb-4" role="alert">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>
                            Valor da compra: {{ convertToCurrencyFormat(sale.total_sale_price) }} - {{
                                sale.total_books }} livros vendidos para <Link class="me-auto"
                                :href="`/dashboard/clients/${sale.client_id}`">{{ sale.client_name }}</Link>
                        </span>
                        <button v-if="sale.status == 'CANCELADA'" type="button"
                            class="btn btn-danger text-white border px-2 btn-sm">Cancelada</button>
                    </div>
                    <hr>
                    <div class="row d-flex justify-content-between">
                        <div class="col-sm-8">Método de pagamento utilizado: {{ sale.payment_method }}</div>
                        <div class="col mt-3 mt-sm-0">Data da venda: {{ sale.created_at }}</div>
                    </div>
                    <hr>
                    <span>Vendedor: <Link class="me-auto ms-2" :href="`/dashboard/users/${sale.user_id}`">{{
                        sale.user_name }}</Link> </span>
                    <template v-if="sale.observation">
                        <hr>
                        <p class="mb-0"><font-awesome-icon class="me-2"
                                :icon="['fas', 'circle-info']" /> {{
                            sale.observation }}</p>
                    </template>
                </div>
                <ConfirmationModal modal_id="saleCancellationModal" modal_title="Confirmação de cancelamento de venda">
                    <template v-slot:modal-text>
                        <p>Você tem certeza que deseja cancelar a venda #{{ sale.id }}?</p>
                        <p class="border-start border-5 border-primary p-3 bg-info bg-opacity-10">Quando cancelada,
                            você não podera mudar o status da venda novamente.</p>
                    </template>
                    <template v-slot:modal-footer>
                        <button type="button" ref="saleCancellationModalCloseButton" class="btn btn-secondary text-white"
                            data-bs-dismiss="modal">Voltar</button>
                        <button @click="cancellationSale()" type="button" class="btn btn-success text-white">Cancelar venda
                        </button>
                    </template>
                </ConfirmationModal>
                <hr>
                <div class="row mt-4">
                    <h5 class="font-bold mb-4">Livros vendidos</h5>
                    <div class="table-responsive">
                        <table class="table col-lg-11 mx-auto table-hover">
                            <thead class="table-success">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Livro</th>
                                    <th class="text-center" scope="col">Quat</th>
                                    <th class="text-center" scope="col">P.Unitário</th>
                                    <th class="text-center" scope="col">P.Total</th>
                                    <th class="text-center" scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(bookSale, index) in bookSales" :key="index">
                                    <th scope="row">{{ parseInt(index) + 1 }}</th>
                                    <td>{{ bookSale.book_name }}</td>
                                    <td class="text-center">{{ bookSale.quantity }}</td>
                                    <td class="text-center"> {{ convertToCurrencyFormat(bookSale.unit_price) }}</td>
                                    <td class="text-center d-flex align-items-center justify-content-center"> {{ convertToCurrencyFormat(bookSale.total_books_price) }} <span class="badge bg-warning text-dark ms-3" v-if="bookSale.aplicaded_discount">{{ bookSale.aplicaded_discount }} %</span></td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/books/${bookSale.book_id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Lear/>
        </div>
        <div class="lear-button">
            <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas" href="#sale-lear"
                role="button" aria-controls="sale-lear">
                Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
            </a>
        </div>
    </Dashboard>
</template>

