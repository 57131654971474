<template>
    <div>
        <img class="d-block mx-auto image-book-sale" :src="imageUrl" alt="" @error="handleImageError" />
    </div>
</template>
  
<script setup>
import { ref, watch, defineProps } from 'vue';

const defaultImageUrl = '/img/book-default-image.png';
const props = defineProps(['url']);
const imageUrl = ref(props.url);

watch(() => props.url, (value) => {
    imageUrl.value = value;
});

const handleImageError = () => {
    imageUrl.value = defaultImageUrl;
};
</script>
  