<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Lear from './Components/Lear.vue';

const props = defineProps({ categories: Object });

const form = useForm({
    name: '',
    description: '',
    visible: true,
    rhema: 0,
    categories: []
});

const store = () => form.post('/dashboard/store-session');
</script>

<template>
    <Dashboard page_title="Criar seção">
        <Breadcrumb currentPageName="Criar seção" />
        <PageNavigationHeader pageTitle="Criar seção" baseRoute="store-session" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row" id="createStoreSession">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store" id="author-creation-form">
                        <div class="row g-3">
                            <div class="col-md-5 p-0">
                                <label for="name" class="form-label">Nome da seção da loja</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name"
                                    v-model="form.name" placeholder="Destaque" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>

                            <div class="col-md-3 col-6">
                                <label for="rhema" class="form-label">Rhema</label>
                                <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="visible" v-model="form.visible">
                                    <label class="form-check-label" for="visible">
                                        Seção visível
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12 p-0">
                                <label for="description" class="form-label">Descrição da seção</label>
                                <small>(opcional)</small>
                                <textarea type="text" class="form-control" id="description" name="description"
                                    v-model="form.description" rows="5"></textarea>
                                <small class="text-danger mt-3" v-if="form.errors.description">{{
                                    form.errors.description
                                }}</small>
                            </div>

                            <h4>Categorias</h4>

                            <ul class="list-group overflow-auto" id="categoryListing">
                                <li class="list-group-item p-3" v-for="(category, index) in categories" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                        :id="'checkBoxCategory' + index" v-model="form.categories">
                                    <label class="form-check-label stretched-link ms-3 fs-13"
                                        :for="'checkBoxCategory' + index" style="cursor: pointer;">{{
                                            category.name
                                        }}</label>
                                </li>
                            </ul>

                        </div>

                        <div class="row d-flex justify-content-center mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#section-lear" role="button" aria-controls="section-lear">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success mt-3 mt-sm-0" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar seção
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

