<script setup>
import { useForm } from '@inertiajs/vue3'

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Lear from './Components/Lear.vue';
import usePreviewImage from '@/Composables/PreviewImage.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const form = useForm({
    name: null,
    description: null,
    visible_public: 1,
    photo: null
});

const store = () => form.post('/dashboard/categories');
</script>

<template>
    <Dashboard page_title="Criar categoria">
        <Breadcrumb currentPageName="Criar categoria" />
        <PageNavigationHeader pageTitle="Criar categoria" baseRoute="categories" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-8">
                                <label for="name" class="form-label">Nome da categoria</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" placeholder="Rhema"
                                    v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-4">
                                <label for="visible_public" class="form-label">Visível na loja</label>
                                <select class="form-select" name="visible_public" id="visible_public"
                                    v-model="form.visible_public">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label for="personal-description" class="form-label">Descrição</label> <small>(opcional)</small>
                                <textarea type="text" name="personal-description" class="form-control"
                                    id="personal-description" v-model="form.description"
                                    placeholder="Forneça um breve resumo desta categoria" rows="5"></textarea>
                                <small class="text-danger mt-3" v-if="form.errors.description">{{
                                    form.errors.description
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="formFile" class="form-label">Selecione uma foto para essa categoria - Tamanho máximo permitido: 2mb</label>
                                <input class="form-control" @input="form.photo = $event.target.files[0]"
                                    type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage">
                                <small class="text-danger mt-3" v-if="form.errors.photo">{{
                                    form.errors.photo
                                }}</small>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <img class="d-block mx-auto preview-image rounded-circle" v-if="imageUrl" :src="imageUrl" />
                            </div>
                        </div>

                        <div class="row d-flex justify-content-between mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#category-creation" role="button" aria-controls="category-creation">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success" type="submit" :disabled="form.processing || imageSizeInMB > 2">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Cadastrar 
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Lear />
    </Dashboard>
</template>

