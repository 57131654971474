<template>
  <div v-if="flashMessage">
    <div :class="`toast bg-white p-0`" id="myToastEl" role="alert" aria-live="assertive" aria-atomic="true"
      data-bs-delay="7000">
      <div class="progress" style="height: 7px;">
        <div :class="`progress-bar bg-${type}`" role="progressbar"
          :style="{ width: progressBarWidth }"></div>
      </div>
      <div class="toast-body">
        <div class="row d-flex align-items-center">
          <div class="col-10">
            <span>{{ flashMessage }}</span>
          </div>
          <div class="col-2">
            <font-awesome-icon :class="`icon text-${type}`" :icon="['fas', icon]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { Toast } from 'bootstrap';

const page = usePage();
const flashMessage = computed(() => page.props.flash.message?.text);
const flashMessageType = computed(() => page.props.flash.message?.type);

const toastTypes = {
  success: 'success',
  error: 'danger',
  info: 'primary',
};

const toastIcon = {
  success: 'circle-check',
  error: 'ban',
  info: 'circle-info',
};

const type = computed(() => toastTypes[flashMessageType.value]);
const icon = computed(() => toastIcon[flashMessageType.value]);

const progressBarWidth = ref('0%');

onMounted(() => {
  const myToastEl = new Toast(document.getElementById('myToastEl'));
  let progress = 0;

  if (flashMessage.value) {
    myToastEl.show();

    const progressInterval = setInterval(() => {
      progress += 1;
      progressBarWidth.value = `${progress}%`;

      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, 70);
  }
});
</script>

