<script setup>
import { ref, reactive, nextTick, computed, watch, defineProps } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import BookImage from '@/Pages/Dashboard/Pages/Books/Components/BookImage.vue';

import Lear from './Components/Lear.vue';

import { VMoney } from 'v-money';
import { vMaska } from "maska";

import useMoney from '@/Composables/Money.vue';
import usePriceFormatter from '@/Composables/PriceFormatter.vue';
import useAxios from "@/Services/Axios.vue";

const props = defineProps({
    books: Array,
    clients: Object,
    paymentMethods: Object,
    errors: Object,
});

const totalBooksPrice = ref(0);
const bookIdInputIsbn = ref();
const book = reactive({
    book_id: null,
    name: null,
    stock: null,
    original_price: null,
    cover_image_url: null,
});

const hasPromotional = reactive({
    status: false,
    promotionalValue: null,
    originalValue: null
});

const { money } = useMoney();
const { convertToCurrencyFormat } = usePriceFormatter();
const { instance: axios } = useAxios();

const form = useForm({
    book_id: '',
    client_id: '',
    payment_method_id: '',
    quantity: '',
    isbn: '',
    created_at: null,
});

const inputValue = ref('');

const seacher = () => {
    axios.get(`/searchByIsbn?isbn=${inputValue.value}`)
        .then((response) => {
            const book_response = response.data.books[0];

            book.name = book_response?.name;
            book.id = book_response?.id;
            book.stock = book_response?.quantity;
            book.cover_image_url = book_response?.cover_image_url;
            form.quantity = 1;

            const promotion = book_response?.current_promotion;
            const isRhemaClient = selectedClient?.value?.type === 'ALUNO_RHEMA';

            if (promotion && selectedClient?.value) {
                if (isRhemaClient && promotion.rhema) {
                    book.original_price = promotion.discounted_price.toString();
                    hasPromotional.status = true;
                    hasPromotional.promotionalValue = promotion.discounted_price;
                    hasPromotional.originalPrice = book_response?.original_price;
                } else if (!promotion.rhema) {
                    book.original_price = promotion.discounted_price.toString();
                    hasPromotional.status = true;
                    hasPromotional.promotionalValue = promotion.discounted_price;
                    hasPromotional.originalPrice = book_response?.original_price;
                }else{
                    book.original_price = book_response?.original_price.toString();
                    hasPromotional.status = false;
                }
                getTotalPriceBooks();
            } else {
                book.original_price = book_response?.original_price.toString();
                hasPromotional.status = false;
                getTotalPriceBooks();
            }
        })
}

const getTotalPriceBooks = () => {
    let priceAsNumber = book.original_price ? parseFloat(book.original_price.replace(',', '.')) : 0;
    let totalPrice = Math.round(priceAsNumber * form.quantity * 100) / 100;

    totalBooksPrice.value = totalPrice.toFixed(2);

    applyDiscount();
};

const blockLimit = () => {
    let limitQuantity = parseInt(stock.value);
    let currentQuantity = parseInt(form.quantity);
    form.quantity = (currentQuantity > limitQuantity) ? stock.value : form.quantity;
    getTotalPriceBooks();
};

const store = () => form.transform((data) => ({
    ...data,
    book_id: book.id
})).post('/dashboard/sales');

watch(() => form.client_id, (newClientId, oldClientId) => {
    if (newClientId !== oldClientId && inputValue.value !== '') {
        seacher();
    }
});

let typingTimer = null;
let applyingDiscount = false;

const selectedClient = computed(() => props.clients.find((client) => client.id === form.client_id));

const applyDiscount = () => {
    if (!applyingDiscount) {
        applyingDiscount = true;
        if (selectedClient.value && selectedClient.value.type === 'PASTOR' && !hasPromotional.status) {
            const discountedPrice = parseFloat(book.original_price) * 0.85;
            totalBooksPrice.value = (discountedPrice * form.quantity).toFixed(2);
        } else {
            getTotalPriceBooks();
        }
        applyingDiscount = false;
    }
};

const compositionSeacher = (event) => {
    inputValue.value = event.target.value;

    if (inputValue.value.length >= 34) {
        event.target.value = inputValue.value.slice(-17);
    } else {
        bookIdInputIsbn.value = inputValue.value;
    }

    clearTimeout(typingTimer);

    typingTimer = setTimeout(async () => {
        await nextTick();
        seacher();
    }, 1000);
};

</script>

<template>
<Dashboard page_title="Vender livro">
    <Breadcrumb currentPageName="Vender livro" />
    <PageNavigationHeader pageTitle="Vender livro" baseRoute="sales" />
    <div class="row mt-2 mx-0">
        <div class="col-lg-12 bg-white rounded-3 border">
            <form class="p-4" @submit.prevent="store">

                <div class="row">
                    <div class="col-md-7">
                        <div class="row g-4">
                            <div class="col-md-12">
                                <label for="client_id" class="form-label">Cliente</label>
                                <div class="input-group">
                                    <select class="form-select" id="client_id" name="client_id" v-model="form.client_id"
                                        required>
                                        <option value="" selected>--- Selecione o cliente ---</option>
                                        <option :value="client.id" v-for="(client, index) in clients" :key="index">{{
                client.name }}
                                        </option>
                                    </select>
                                    <a target="_blank" :href="`/dashboard/clients/create`"
                                        class="btn btn-primary font-semibold text-white float-end">Novo cliente</a>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <label for="book_id" class="form-label">ISBN</label>
                                <input type="text" class="form-control" ref="bookIdInputIsbn" v-maska
                                    data-maska="###-##-####-###-# ###-##-####-###-#" @input="compositionSeacher($event)"
                                    placeholder="Informe o ISBN do livro" autocomplete="off" required
                                    @keydown.enter.prevent>
                            </div>
                            <div class="col-md-12">
                                <label for="book_id" class="form-label">Livro</label>
                                <input type="text" class="form-control" id="book_id" v-model="book.name" name="book_id"
                                    placeholder="" autocomplete="off" disabled>
                            </div>

                            <div class="col-6 col-md-4">
                                <label for="stock" class="form-label">Estoque atual</label>
                                <input type="text" class="form-control" v-model="book.stock" id="stock" name="stock"
                                    disabled>
                            </div>

                            <div class="col-6 col-md-4">
                                <label for="total_books_price" class="form-label">Valor total</label>
                                <div class="input-group">
                                    <input type="text" disabled class="form-control" id="total_books_price"
                                        name="total_books_price" v-model="totalBooksPrice" v-money="money" required>
                                    <small class="text-danger mt-3" v-if="form.errors.total_books_price">{{
                form.errors.total_books_price
            }}</small>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label for="quantity" class="form-label">Quantidade</label>
                                <input type="text" class="form-control" v-model="form.quantity" @keyup="blockLimit"
                                    id="quantity" name="quantity" autocomplete="off" required v-maska data-maska="##">
                                <small class="text-danger mt-3" v-if="form.errors.quantity">{{ form.errors.quantity
                                    }}</small>
                            </div>

                            <div class="col-md-6">
                                <label for="payment_method_id" class="form-label">Método de pagamento</label>
                                <select class="form-select" id="payment_method_id" name="payment_method_id"
                                    v-model="form.payment_method_id" required>
                                    <option value="" selected>--- Selecione ---</option>
                                    <option :value="payment_method.id" v-for="(payment_method, index) in paymentMethods"
                                        :key="index">{{
                payment_method.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="created_at" class="form-label">Data de venda</label>
                                <input type="datetime-local" class="form-control" v-model="form.created_at"
                                    id="created_at" name="created_at">
                                <small class="text-danger mt-3" v-if="form.errors.created_at">{{ form.errors.created_at
                                    }}</small>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="row mt-3">
                            <BookImage :url="`/storage/${book.cover_image_url}`" />
                        </div>
                        <div v-if="book.stock <= 0 && book.stock != null">
                            <p
                                class="border-start border-5 border-danger font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14 mt-3">
                                Este livro não está mais disponível em estoque.
                            </p>
                        </div>
                        <div v-else>
                            <div class="row mt-3 p-3" v-if="hasPromotional.status">
                                <hr>
                                <p
                                    class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14">
                                    <span>Promoção encontrada</span><br>
                                    <span>
                                        Livro(s) de <span><del>{{ convertToCurrencyFormat(hasPromotional.originalPrice *
                form.quantity)
                                                }}</del></span>
                                        por
                                        {{ convertToCurrencyFormat(hasPromotional.promotionalValue * form.quantity) }}
                                    </span>
                                </p>
                            </div>
                            <div v-if="!hasPromotional.status && selectedClient && selectedClient.type == 'PASTOR'">
                                <hr>
                                <p
                                    class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14">
                                    <span>Cliente classificado como pastor</span><br>
                                    <span v-if="book.original_price">
                                        Livro(s) de <span><del>{{ convertToCurrencyFormat(book.original_price *
                form.quantity)
                                                }}</del></span>
                                        por
                                        R$ {{ totalBooksPrice }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="my-2">
                        <hr>
                    </div>
                </div>

                <div class="row d-flex justify-content-between">
                    <div class="col">
                        <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                            href="#sale-lear" role="button" aria-controls="sale-lear">
                            Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                        </a>
                    </div>
                    <div class="col text-end">
                        <button class="btn btn-success" type="submit"
                            :disabled="form.processing || !book.name || book.stock <= 0">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Finalizar venda
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <Lear />
    </div>
</Dashboard>
</template>
