<template>
    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin navigation-buttons mb-4">
        <div>
            <h4 class="mb-md-0">{{ pageTitle }}</h4>
        </div>
        <div class="d-flex align-items-center flex-wrap text-nowrap">
            <Link :href="`/dashboard/${baseRoute}`"
                :class="{ 'active': $page.component === `Dashboard/Pages/${folderName}/Index` }" class="me-2 mb-2 mb-md-0">
            <font-awesome-icon class="me-2" :icon="['fas', 'bars-staggered']" />
            Listagem
            </Link>
            <Link v-if="showAdd" :href="`/dashboard/${baseRoute}/create`"
                :class="{ 'active': $page.component === `Dashboard/Pages/${folderName}/Create` }" class="mb-2 mb-md-0">
                <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
            Criar
            </Link>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    pageTitle: String,
    baseRoute: String,
    showAdd: {
        type: Boolean,
        default: true
    }
});

const folderName = computed(() => props.baseRoute.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(''));
</script>