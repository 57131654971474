<template>
    <div class="card placeholder-glow card-hover card-author p-1">
        <div v-if="imageHasBeenUploaded" class="card-header text-center text-sm-start font-semibold px-3 py-2 text-uppercase bg-white border-0">
            <span class="fs-14">{{ author.name }}</span>
        </div>
        <div v-else class="card-header col-12 col-sm-4 px-3 py-2 border-0 placeholder bg-primary"></div>
        <div class="card-body">
            <div class="placeholder-image d-block mx-auto" v-if="!imageHasBeenUploaded"></div>
            <img class="d-block mx-auto preview-image border-warning"
                :src="`/storage/${author.profile_picture_url}`" alt="" @load="uploadedImage"
                :class="{ 'loaded': imageHasBeenUploaded }">
            <template v-if="!imageHasBeenUploaded">
                <p>
                    <span class="placeholder col-6 bg-primary"></span>
                </p>
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder float-end col-4"
                    :aria-hidden="imageHasBeenUploaded"></a>
            </template>
            <template v-else>
                <p class="font-semibold mt-3 books-available fs-13">{{ author.book_total }} livro(s) disponíveis</p>
                <Link :href="`/autores/${author.clean_name}`"
                    class="btn btn-primary stretched-link float-sm-end text-white font-semibold fs-13"
                    :aria-hidden="!imageHasBeenUploaded">Ver livros</Link>
            </template>
        </div>
    </div>
</template>
  
<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';

const imageHasBeenUploaded = ref(false);

const props = defineProps({ author: Object });

const uploadedImage = () => imageHasBeenUploaded.value = true;
</script>
 
  
  
  
  