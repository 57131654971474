<script>
import axios from "axios";

export default function useAxios() {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    const instance = axios.create({
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: process.env.APP_URL
    });

    return {
        instance
    }
}
</script>



