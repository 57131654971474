<script setup>
import { ref, computed } from "vue";
import { usePage, router, useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const props = defineProps({
    paymentMethod: Object,
    errors: Object
});

const form = useForm({
    name: props.paymentMethod.name,
});

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const paymentMethodEditModalCloseButton = ref(null);

const update = () => form.put(`/dashboard/payment-methods/${props.paymentMethod.id}`);
const destroy = () => {
    paymentMethodEditModalCloseButton.value.click();
    router.delete(`/dashboard/payment-methods/${props.paymentMethod.id}`);
};
</script>

<template>
    <Dashboard :page_title="paymentMethod.name">
        <div class="row mx-0" id="show-payment_method">
            <Breadcrumb :currentPageName="paymentMethod.name" :linkList="[
                { previous_page_url: 'payment-methods', previous_page_name: 'Métodos' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do método</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon 
                                :href="`/dashboard/payment-methods/${paymentMethod.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />
                            <ButtonWithIcon v-if="permissions.destroy_payment_method" 
                                href="#" 
                                background="danger" 
                                color="white"
                                icon="trash-can" 
                                :linkInertia="false" 
                                message="Deletar"
                                dataBsToggle="modal"
                                dataBsTarget="#paymentMethodEditModal"
                            />
                        </div>
                    </div>
                    <hr>
                    <form class="border-0 shadow-none" @submit.prevent="update">
                        <div class="row g-4">
                            <div class="col-12">
                                <label for="name" class="form-label">Nome do método</label>
                                <input type="text" autocomplete="off" id="name" class="form-control"
                                    v-model="form.name" required>
                                <small class="text-danger" v-if="form.errors.name">{{
                                    errors.name
                                }}</small>
                            </div>
                        </div>
                        <button class="btn btn-success mt-3  float-end" type="submit" :disabled="form.processing">
                            <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            Atualizar dados
                        </button>
                    </form>

                    <ConfirmationModal modal_id="paymentMethodEditModal"
                        modal_title="Confirmação de remoção do método de pagamento">
                        <template v-slot:modal-text>
                            Você tem certeza que deseja excluir o método de pagamento {{ paymentMethod.name }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="paymentMethodEditModalCloseButton"
                                class="btn btn-secondary text-white" data-bs-dismiss="modal">Voltar</button>
                            <button @click="destroy" type="button" class="btn btn-danger text-white">Deletar método
                            </button>
                        </template>
                    </ConfirmationModal>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

