<template>
    <div id="footer">
        <footer class="container-fluid">
            <div class="row text-white p-4 mt-2">
                <div class="col-md-3">
                    <div class="text-uppercase font-semibold fs-6">Acesso rápido</div>
                    <div class="list-group bg-transparent border-0">
                        <Link href="/livros" class="list-group-item px-0 py-2 bg-transparent border-0 text-white">Todos os
                        livros</Link>
                        <Link href="/autores" class="list-group-item px-0 py-2 bg-transparent border-0 text-white">Autores
                        </Link>
                        <Link href="/temas" class="list-group-item px-0 py-2 bg-transparent border-0 text-white">
                        Temas</Link>
                    </div>
                </div>
                <div class="col-md-7 mt-3 mt-sm-0">
                    <div class="text-uppercase font-semibold fs-6">Entre em contato</div>
                    <ul class="list-group bg-transparent border-0">
                        <li class="list-group-item px-0 py-2">
                            <font-awesome-icon class="me-2" :icon="['fab', 'whatsapp']" />
                            {{ storeData.mobile_number}}
                        </li>
                        <li class="list-group-item px-0 py-2">
                            <font-awesome-icon class="me-2" :icon="['fas', 'phone']" />
                            {{ storeData.phone_number }}
                        </li>
                        <li class="list-group-item px-0 py-2">
                            <font-awesome-icon class="me-2" :icon="['fas', 'envelope']" />
                            {{ storeData.email }}
                        </li>
                        <li class="list-group-item px-0 py-2">
                            <font-awesome-icon class="me-2" :icon="['fas', 'map-location-dot']" />
                            {{ storeData.address }}
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 social-media mt-3 mt-sm-0">
                    <div class="text-uppercase font-semibold fs-6 mb-4">Redes sociais</div>
                    <a class="me-2 rounded-3" href="https://www.facebook.com/VerboPauloAfonso" aria-label="Link Facebook"
                        target="_blank">
                        <font-awesome-icon :icon="['fab', 'facebook']" />
                    </a>
                    <a class="me-2 rounded-3" href="https://www.youtube.com/@verbodavidapauloafonso9814"
                        aria-label="Link Youtube" target="_blank">
                        <font-awesome-icon :icon="['fab', 'youtube']" />
                    </a>
                    <a class="rounded-3" href="https://www.instagram.com/verbodavidapauloafonso/"
                        aria-label="Link Instagram" target="_blank">
                        <font-awesome-icon :icon="['fab', 'instagram']" />
                    </a>
                </div>
            </div>
        </footer>
        <div class="p-3 bg-warning text-dark font-bold copywriter text-center">
            VerboShop Paulo Afonso - {{ new Date().getFullYear() }}
        </div>
    </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';

const page = usePage();

const storeData = page.props.store.data;
</script>
