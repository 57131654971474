import './bootstrap';
import './fontAwesome';

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import * as Sentry from "@sentry/vue";

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });
    const env = import.meta.env.VITE_ENVIRONMENT;

    if(env !== 'devel'){
      Sentry.init({
        app,
        environment: import.meta.env.VITE_ENVIRONMENT,
        dsn: 'https://a0cf694342f9967174b3c09278fd4d11@o4506570701930496.ingest.us.sentry.io/4506708648198144',
        integrations: [
          new Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 0.5,
        trackComponents: true,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      });
    }

    app.component("font-awesome-icon", FontAwesomeIcon)
    app.use(plugin);
    app.mount(el);

    return app;
  },
});
