<template>
	<div class="card-hover placeholder-glow h-100 pe-auto card-book" data-aos="fade-right" data-aos-duration="3000">
		<span class="discount" v-if="book.current_promotion && book.quantity && imageHasBeenUploaded">{{ book.current_promotion.discount_percentage + '% OFF' }}</span> 
		<span class="sold-off" v-if="!book.quantity && imageHasBeenUploaded">Esgotado</span> 
		<div class="card-body p-0 p-3">
            <div class="card-img">
				<img class="d-block mx-auto"
                :src="`/storage/${book.cover_image_url}`" alt="" @load="uploadedImage"
                :class="{ 'loaded': imageHasBeenUploaded }">
			</div>
			<div v-if="imageHasBeenUploaded" class="card-title text-center">
            	<span>{{ book.name }}</span>
        	</div>
        	<div v-else class="card-title col-12 border-0 text-center placeholder bg-primary"></div>
			<div class="card-text" v-if="imageHasBeenUploaded">
				<p class="card-price text-center" v-if="!book.current_promotion">
					{{ formatPrice(book.original_price) }}
				</p>

				<p v-else class="card-price text-center">
					{{ formatPrice(book.current_promotion.discounted_price) }}
				</p>
			</div>
			<div v-else>
				<p class="placeholder-glow p-3">
					<span class="placeholder col-12"></span>
				</p>
			</div>
			<Link :href="`/livros/${book.clean_name}`" class="stretched-link" :aria-label="book.name"></Link>
		</div>
	</div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();
const formatPrice = (value) => convertToCurrencyFormat(value);

const props = defineProps({ book: Object });

const imageHasBeenUploaded = ref(false);

const uploadedImage = () => imageHasBeenUploaded.value = true;

onMounted(() => {
    AOS.init({
        once: true,
    });
});
</script>