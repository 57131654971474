
<script setup>
import { reactive, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const page = usePage();
const permissionsUser = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    permissions: Object
});

const searchForm = reactive({ display_name: null });

const seacher = () => router.get('/dashboard/permissions', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de permissões">
        <Breadcrumb currentPageName="Listagem de permissões" />
        <PageNavigationHeader pageTitle="Permissões" baseRoute="permissions" :showAdd="permissionsUser.create_permission" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto p-3">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-3">
                                <label for="display_name" class="form-label">Nome da permissão</label>
                                <input type="text" id="display_name" class="form-control" placeholder="Criar livro"
                                    v-model="searchForm.display_name" autocomplete="on">
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary float-end text-white" @click="seacher"><font-awesome-icon class="me-2" :icon="['fas', 'magnifying-glass']" /> Buscar</a>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive-sm col-11 mx-auto">
                        <table class="table table-hover" id="table-permissions">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Nome legível</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="permissions.meta.total > 0">
                                <tr v-for="(permission, index) in permissions.data" :key="index">
                                    <th scope="row">{{ parseInt(index) + 1 }}</th>
                                    <td>{{ permission.name }}</td>
                                    <td>{{ permission.display_name }}</td>
                                    <td class="text-center">{{ permission.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/permissions/${permission.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissionsUser.edit_permission" :href="`/dashboard/permissions/edit/${permission.id}`" class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />  
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhuma permissão encontrada
                                </td>
                            </tr>
                        </table>
                        <div class="row mb-3" v-if="permissions.meta.total > 0">
                            <span class="text-end fs-14 font-semibold">{{ permissions.meta.total }} permissões
                                encontradas</span>
                        </div>
                        <pagination :data="permissions.meta" reference_date="permissions" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
