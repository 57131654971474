<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const form = useForm({ name: null });

const store = () => form.post('/dashboard/payment-methods');
</script>

<template>
    <Dashboard page_title="Adicionar método de pagamento">
        <Breadcrumb currentPageName="Adicionar método de pagamento" />
        <PageNavigationHeader pageTitle="Adicionar" baseRoute="payment-methods" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-12">
                                <label for="name" class="form-label">Nome do método</label>
                                <input type="text" autocomplete="on" class="form-control" id="name" placeholder="PIX"
                                    v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success mt-3 mt-sm-0" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar método
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

