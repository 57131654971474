<script setup>
import { computed } from "vue";
import { Link, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    sessions: Object
});
</script>

<template>
    <Dashboard page_title="Listagem das Seções">
        <Breadcrumb currentPageName="Listagem das Seções" />
        <PageNavigationHeader pageTitle="Seções da loja" baseRoute="store-session" :showAdd="permissions.create_store_session" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <div class="table-responsive-sm col-11 mx-auto p-3">
                        <table class="table table-hover" id="table-clients">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Visível</th>
                                    <th class="text-center">Ordem</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(session, index) in sessions.data" :key="index">
                                    <td scope="row">{{ parseInt(index + 1) }}</td>
                                    <td>{{ session.name }}</td>
                                    <td class="text-center">{{ session.visible ? 'Sim' : 'Não' }}</td>
                                    <td class="text-center">{{ session.display_sequence }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/store-session/${session.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_store_session" :href="`/dashboard/store-session/edit/${session.id}`" class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

