<script setup>
import { reactive, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    roles: Object
});

const searchForm = reactive({ display_name: null });

const seacher = () => router.get('/dashboard/roles', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de funções">
        <Breadcrumb currentPageName="Listagem de funções" />
        <PageNavigationHeader pageTitle="Funções" baseRoute="roles" :showAdd="permissions.edit_role" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto p-3">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-3">
                                <label for="display_name" class="form-label">Nome da função</label>
                                <input type="text" id="display_name" autocomplete="on" class="form-control" placeholder="Administrador"
                                    v-model="searchForm.display_name">
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary float-end text-white" @click="seacher"><font-awesome-icon class="me-2" :icon="['fas', 'magnifying-glass']" /> Buscar</a>
                    </form>

                    <hr class="col-11 mx-auto">

                    <div class="table-responsive-sm col-11 mx-auto">
                        <table class="table table-hover" id="table-roles">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Nome legível</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="roles.meta.total > 0">
                                <tr v-for="(role, index) in roles.data" :key="index">
                                    <th scope="row">{{ role.id }}</th>
                                    <td>{{ role.name }}</td>
                                    <td>{{ role.display_name }}</td>
                                    <td class="text-center">{{ role.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/roles/${role.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_role" :href="`/dashboard/roles/edit/${role.id}`" class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhuma função encontrada
                                </td>
                            </tr>
                        </table>
                        <div class="row mb-3" v-if="roles.meta.total > 0">
                            <span class="text-end fs-14 font-semibold">{{ roles.meta.total }} funções encontradas</span>
                        </div>
                        <pagination :data="roles.meta" reference_date="roles" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

