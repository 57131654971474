<script setup>
import { onMounted, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import BarChart from '../../Graphics/BarChart.vue';
import LineChart from '../../Graphics/LineChart.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    bestSellingBooks: Object,
    movementDetails: Object,
    namesLastMonths: Object
});

const formatRelativeDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    const diffMs = now - date;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffHours < 24) {
        return 'Hoje';
    } else {
        return `${diffDays} dias atrás`;
    }
}
</script>

<template>
    <Dashboard page_title="Rotatividade dos livros">
        <h4 class="mb-4">Rotatividade dos livros</h4>

        <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Movimentações</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                    type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Gráficos</button>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                tabindex="0">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="responsive-table col-11 mx-auto">
                                <table class="table table-hover" id="table-books">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Livro</th>
                                            <th class="text-center" scope="col">Total</th>
                                            <th class="text-center" scope="col">Última</th>
                                            <th class="text-center" scope="col">Vendas</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(book, index) in bestSellingBooks" :key="index">
                                            <td scope="row">{{ parseInt(index) + 1 }}</td>
                                            <td>{{ book.name }}</td>
                                            <td class="text-center">{{ book.total_books_sold }}</td>
                                            <td class="text-center">{{ formatRelativeDate(book.last_sale_date) }}</td>
                                            <td class="text-center">
                                                <Link :href="`/dashboard/sales?book_id=${book.id}`" class="btn btn-success">
                                                    <font-awesome-icon :icon="['fas', 'eye']" />
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div class="row">
                    <div class="col col-md-6">
                        <div class="card p-4">
                            <BarChart style="width: 400px;" :labels="namesLastMonths" :data="movementDetails.total_sales"
                                unitMeasure="R$" color="#239B56" title="Totais de vendas dos ultimos 4 meses" />
                        </div>
                    </div>

                    <div class="col col-md-6">
                        <div class="card p-4">
                            <LineChart style="width: 400px;" :labels="namesLastMonths"
                                :data="movementDetails.sales_increase" unitMeasure="%"
                                title="Taxa de % de vendas dos ultimos 4 meses" />
                        </div>
                    </div>

                    <div class="col col-md-6">
                        <div class="card p-4 mt-4">
                            <BarChart style="width: 400px;" :labels="namesLastMonths" :data="movementDetails.quantitys"
                                unitMeasure="Livros" color="#F5CBA7"
                                title="Quantidade de livros vendidos nos ultimos 4 meses" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>