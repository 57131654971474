<script setup>
import { reactive, onMounted, computed } from "vue";
import { router, Link, usePage } from "@inertiajs/vue3";

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({ 
    promotions: Object
});

const searchForm = reactive({ name: null });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
}

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('name')) {
        searchForm.name = urlParams.get('name');
    }
});

const seacher = () => router.get('/dashboard/promotions', searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de promoções">
        <Breadcrumb currentPageName="Listagem de promoções" />
        <PageNavigationHeader pageTitle="Promoções" baseRoute="promotions" :showAdd="permissions.edit_promotion" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form action="" class="col-11 mx-auto p-3">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-3">
                                <label for="name" class="form-label">Nome da promoção</label>
                                <input autocomplete="on" type="text" id="name" v-model="searchForm.name" class="form-control" placeholder="Natal"
                                    @input="compositionSeacher('name', $event)">
                            </div>
                        </div>
                    </form>
                    <hr class="col-11 mx-auto">
                    <div class="table-responsive-sm col-11 mx-auto p-3">
                        <table class="table table-hover" id="table-promotions">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Livros</th>
                                    <th class="text-center" scope="col">Criação</th>
                                    <th class="text-center" scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody v-if="promotions.meta.total > 0">
                                <tr v-for="(promotion, index) in promotions.data" :key="index">
                                    <td scope="row">{{ parseInt(index) + 1 }}</td>
                                    <td>{{ promotion.name }}</td>
                                    <td class="text-center">{{ promotion.book_total }}</td>
                                    <td class="text-center">{{ promotion.created_at }}</td>
                                    <td class="text-center">
                                        <Link :href="`/dashboard/promotions/${promotion.id}`" class="btn btn-success">
                                            <font-awesome-icon :icon="['fas', 'eye']" />
                                        </Link>
                                        <Link v-if="permissions.edit_promotion" :href="`/dashboard/promotions/edit/${promotion.id}`" class="btn btn-primary ms-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                            <tr class="text-center" v-else>
                                <td colspan="7" class="p-3 font-semibold fs-14">
                                    Nenhuma promoção encontrada
                                </td>
                            </tr>
                            <tfoot>
                                <tr class="border border-white" v-if="promotions.meta.total > 0">
                                    <td class="text-end" colspan="6">
                                        <span class="fs-14 font-semibold">{{ promotions.meta.total }} promoções
                                            encontradas
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <Pagination :data="promotions.meta" reference_date="promotions" />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

