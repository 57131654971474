<script setup>
import { computed, ref } from 'vue';
import { Link, useForm, router, usePage } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue'
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';
import Lear from "./Components/Lear.vue";

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    errors: Object,
    order: Object
});

const orderApprovalModalCloseButton = ref(null);
const orderCompletionModalCloseButton = ref(null);
const orderCancellationModalCloseButton = ref(null);

const { convertToCurrencyFormat } = usePriceFormatter();

const form = useForm({
    observation: props.order.observation
})

const removeBook = (bookOrderId) => {
    if (confirm('Remover livro do pedido?')) {
        router.delete(`/dashboard/book-orders/${bookOrderId}`);
    }
};

const totalOrderAmount = computed(() => convertToCurrencyFormat(props.order.total_orders_price));

const update = () => form.put(`/dashboard/orders/${props.order.id}`);

const changeOrderStatus = (status, ref) => {
    router.put(`/dashboard/orders/update-order-status/${props.order.id}`, { status });
    ref.click();
} 
</script>

<template>
    <Dashboard :page_title="`Pedido nº ${order.id}`">
        <div class="row mx-0">
            <Breadcrumb :currentPageName="`Pedido nº ${order.id}`" :linkList="[
                { previous_page_url: 'orders', previous_page_name: 'Pedidos' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex mb-3 p-0 align-items-center">
                        <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                            <ButtonWithIcon 
                                :href="`/dashboard/orders/${order.id}`" 
                                background="primary" 
                                color="white"
                                icon="eye" 
                                message="Visualizar" 
                                additionalStyles="me-2"
                            />
                        </div>
                        <div class="col text-start text-sm-end">
                            <ButtonWithIcon v-if="order.status == 'PENDENTE' && permissions.approve_order" 
                                href="#" 
                                background="primary" 
                                color="white"
                                icon="circle-check" 
                                :linkInertia="false" 
                                message="Aprovar"
                                dataBsToggle="modal"
                                dataBsTarget="#orderApprovalModal"
                                additionalStyles="me-2"
                            />

                            <ButtonWithIcon  v-else-if="order.status == 'APROVADO' && permissions.finalize_order"
                                href="#" 
                                background="success" 
                                color="white"
                                icon="circle-check" 
                                :linkInertia="false" 
                                message="Finalizar pedido"
                                dataBsToggle="modal"
                                dataBsTarget="#orderCompletionModal"
                                additionalStyles="me-2"
                            />

                            <template v-if="order.status != 'FINALIZADO' && order.status != 'CANCELADO'">
                                <ButtonWithIcon  
                                    href="#" 
                                    background="danger" 
                                    color="white"
                                    icon="ban" 
                                    :linkInertia="false" 
                                    message="Cancelar"
                                    dataBsToggle="modal"
                                    dataBsTarget="#orderCancellationModal"
                                />
                            </template>
                        </div>
                    </div>
                    <hr>
                    <div class="alert alert-info mt-4" role="alert">
                        <font-awesome-icon class="me-2"
                                :icon="['fas', 'circle-info']" /> Pedido composto por {{ order.total_books }} livros
                        que
                        custam um total de <span ref="total_books">{{
                            totalOrderAmount }}</span>
                    </div>
                    <form @submit.prevent="update()">
                        <div class="row g-2 py-2">
                            <div class="col-md-12">
                                <label for="observation" class="form-label">Observações gerais do pedido</label>
                                <textarea type="text" v-model="form.observation" class="form-control" id="observation"
                                    name="observation" rows="3"></textarea>
                            </div>
                            <div class="text-end">
                                <button class="btn btn-success float-end my-3" type="submit" :disabled="form.processing">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Atualizar observações
                                </button>
                            </div>
                        </div>
                    </form>

                    <ConfirmationModal modal_id="orderApprovalModal" modal_title="Confirmação de aprovação de pedido">
                        <template v-slot:modal-text>
                            Você tem certeza que deseja aprovar o pedido {{ order.id }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="orderApprovalModalCloseButton" class="btn btn-secondary text-white"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="changeOrderStatus('APROVADO', orderApprovalModalCloseButton)" type="button"
                                class="btn btn-success text-white">Aprovar pedido
                            </button>
                        </template>
                    </ConfirmationModal>

                    <ConfirmationModal modal_id="orderCompletionModal" modal_title="Confirmação de finalização de pedido">
                        <template v-slot:modal-text>
                            <p>Você tem certeza que deseja finalizar o pedido {{ order.id }}?</p>
                            <p class="border-start border-5 border-primary p-3 bg-info bg-opacity-10">Quando finalizado,
                                os livros do pedido serão automaticamente adicionados ao estoque da loja.</p>
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="orderCompletionModalCloseButton" class="btn btn-secondary text-white"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="changeOrderStatus('FINALIZADO', orderCompletionModalCloseButton)" type="button"
                                class="btn btn-success text-white">Finalizar pedido
                            </button>
                        </template>
                    </ConfirmationModal>

                    <ConfirmationModal modal_id="orderCancellationModal"
                        modal_title="Confirmação de cancelamento de pedido">
                        <template v-slot:modal-text>
                            <p>Você tem certeza que deseja cancelar o pedido {{ order.id }}?</p>
                            <p class="border-start border-5 border-primary p-3 bg-info bg-opacity-10">Quando cancelado,
                                você não podera mudar o status do pedido novamente.</p>
                        </template>
                        <template v-slot:modal-footer>
                            <button type="button" ref="orderCancellationModalCloseButton"
                                class="btn btn-secondary text-white" data-bs-dismiss="modal">Voltar</button>
                            <button @click="changeOrderStatus('CANCELADO', orderCancellationModalCloseButton)" type="button"
                                class="btn btn-success text-white">Cancelar pedido
                            </button>
                        </template>
                    </ConfirmationModal>

                    <div class="table-responsive" v-if="order.book_order.length">
                        <hr>
                        <table class="table col-lg-11 mx-auto table-hover">
                            <thead class="table-success">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Livro</th>
                                    <th class="text-center" scope="col">Quatidade</th>
                                    <th class="text-center" scope="col">P.Unitário</th>
                                    <th class="text-center" scope="col">P.Total</th>
                                    <th class="text-center" scope="col"
                                        v-if="order.status == 'PENDENTE' || order.status == 'APROVADO'">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(book_order, index) in order.book_order" :key="index">
                                    <th scope="row">{{ book_order.book_id }}</th>
                                    <td>{{ book_order.book_name }}</td>
                                    <td class="text-center">{{ book_order.quantity }}</td>
                                    <td class="text-center"> {{ convertToCurrencyFormat(book_order.unit_price) }}</td>
                                    <td class="text-center"> {{ convertToCurrencyFormat(book_order.total_books_price) }}
                                    </td>
                                    <td class="text-center" v-if="order.status == 'PENDENTE' || order.status == 'APROVADO'">
                                        <Link :href="`/dashboard/book-orders/edit/${book_order.id}`"
                                            class="btn btn-primary me-2">
                                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                        </Link>
                                        <a @click="removeBook(book_order.id)" class="btn btn-danger" href="#">
                                            <font-awesome-icon :icon="['fas', 'trash-can']" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Lear />
        </div>
        <div class="lear-button">
            <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas" href="#order-lear"
                role="button" aria-controls="order-lear">
                Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
            </a>
        </div>
    </Dashboard>
</template>

