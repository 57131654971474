<script setup>
import { onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({ permissions: Object });

const form = useForm({ 
    name: null, 
    display_name: null, 
    description: null, 
    permissions: [] 
});

const store = () => form.post('/dashboard/roles');

onMounted(() => {
    form.permissions = props.permissions.map((permission) => permission.id)
});
</script>

<template>
    <Dashboard page_title="Criar função">
        <Breadcrumb currentPageName="Criar função" />
        <PageNavigationHeader pageTitle="Criar função" baseRoute="roles" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-4">
                                <label for="name" class="form-label">Nome da função</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name" placeholder="admin"
                                    v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-8">
                                <label for="display_name" class="form-label">Nome legível da função</label>
                                <input type="text" class="form-control" id="display_name" display_name="display_name"
                                    placeholder="Administrador" v-model="form.display_name" autocomplete="off" required>
                                <small class="text-danger mt-3" v-if="form.errors.display_name">{{ form.errors.display_name
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição da função</label>
                                <input type="text" class="form-control" id="description" description="description"
                                    placeholder="Essa função irá permite que um usuário acesse boa parte das funcionalidades do sistema"
                                    v-model="form.description" autocomplete="off" required>
                                <small class="text-danger mt-3" v-if="form.errors.description">{{ form.errors.description
                                }}</small>
                            </div>
                            <ul class="list-group overflow-auto normal-listing p-2">
                                <li class="list-group-item p-3" v-for="(permission, index) in permissions" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="permission.id"
                                        :id="'checkBoxPermission' + index" v-model="form.permissions">
                                    <label class="form-check-label stretched-link ms-3" :for="'checkBoxPermission' + index"
                                        style="cursor: pointer;">{{ permission.display_name }} - {{ permission.description
                                        }}</label>
                                </li>
                            </ul>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit" :disabled="form.processing">Criar função</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

