<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import usePreviewImage from '@/Composables/PreviewImage.vue';
import Lear from './Components/Lear.vue';

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

import { vMaska } from "maska";

const form = useForm({
    name: null,
    type: 'COMUM',
    email: null,
    phone_number: null,
    //profile_image_url: null
});
const store = () => form.post('/dashboard/clients');
</script>

<template>
    <Dashboard page_title="Criar cliente">
        <Breadcrumb currentPageName="Criar cliente" />
        <PageNavigationHeader pageTitle="Criar cliente" baseRoute="clients" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="name" class="form-label">Nome do cliente</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name"
                                    placeholder="Gabriela Barbosa" v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-6">
                                <label for="email" class="form-label">Email</label> <small>(opcional)</small>
                                <input type="text" autocomplete="off" class="form-control" id="email" namw="email"
                                    placeholder="email@dominio.com" v-model="form.email">
                                <small class="text-danger mt-3" v-if="form.errors.email">{{ form.errors.email
                                }}</small>
                            </div>
                            <div class="col-md-6">
                                <label for="phone_number" class="form-label">Contato</label> <small>(opcional)</small>
                                <input type="text" autocomplete="off" class="form-control" id="phone_number"
                                    namw="phone_number" placeholder="00 0000-0000" v-model="form.phone_number">
                                <small class="text-danger mt-3" v-if="form.errors.phone_number">{{ form.errors.phone_number
                                }}</small>
                            </div>
                            <!-- <div class="col-md-9">
                                <label for="formFile" class="form-label">Selecione uma foto - Tamanho máximo permitido:
                                    2mb</label> <small>(opcional)</small>
                                <input class="form-control" @input="form.profile_image_url = $event.target.files[0]"
                                    type="file" id="formFile" accept="image/png,image/jpeg" @change="previewImage">
                                <small class="text-danger mt-3" v-if="form.errors.profile_image_url">{{
                                    form.errors.profile_image_url
                                }}</small>
                            </div> -->
                            <div class="col-md-6 col-6">
                                <label for="type" class="form-label">Tipo de cliente</label>
                                <select class="form-select" name="type" id="type" v-model="form.type">
                                    <option value="COMUM">Comum</option>
                                    <option value="PASTOR">Pastor</option>
                                    <option value="ALUNO_RHEMA">Aluno Rhema</option>
                                </select>
                            </div>
                            <!-- <div class="col-md-11 mx-auto mt-4">
                                <img class="d-block mx-auto preview-image rounded-circle" v-if="imageUrl" :src="imageUrl" />
                            </div> -->
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#client-lear" role="button" aria-controls="client-lear">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                            </div>
                            <div class="col text-end">
                                <button class="btn btn-success float-end" type="submit"
                                    :disabled="form.processing || imageSizeInMB > 2">
                                    <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Criar cliente
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

