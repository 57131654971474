<script setup>
import { onMounted, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { Tooltip } from 'bootstrap';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import ActivityLogs from '@/Pages/Dashboard/Shared/ActivityLogs.vue';
import RecordMovements from './Components/RecordMovements.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();

const page = usePage();
const permissions = computed(() => page.props.auth.permissions);

const props = defineProps({
    authors: Object,
    book: Object,
    categories: Object,
    activitys: Object,
    movementRecords: Object,
});

onMounted(() => {
    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
    })
});

const convertYouTubeLink = (link) => {
    if (link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];

        return link;;
    }
}
</script>

<template>
    <Dashboard :page_title="book.name">
        <div class="row mt mx-0">
            <Breadcrumb :currentPageName="book.name" :linkList="[
                { previous_page_url: 'books', previous_page_name: 'Livros' }
            ]" />
            <div class="col-lg-12">
                <div id="show-book">
                    <div class="row card">
                        <template v-if="book.current_promotion">
                            <div class="col-lg-12 bg-light p-3">
                                <div class="row d-flex justify-content-between align-items-center">
                                    <span class="font-bold col-12 col-lg-8">Custando {{
                                        convertToCurrencyFormat(book.current_promotion.discounted_price) }} na promoção de
                                        {{ book.current_promotion.name }}</span>
                                    <div class="col col-lg-4 text-end mt-2 mt-sm-0">
                                        <ButtonWithIcon
                                            :href="`/dashboard/promotions/${book.current_promotion.promotion_id}`"
                                            background="warning" color="dark" icon="percent" message="Ver promoção"
                                            additionalStyles="me-3" />
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </template>
                        <div class="row">
                            <div v-if="permissions.edit_book" class="text-end my-3 p-2">
                                <button type="button" class="btn btn-success me-2" data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    :data-bs-title="`${book.visible ? 'Vísivel na loja' : 'Não vísivel na loja'}`">
                                    <font-awesome-icon :icon="['fas', book.visible ? 'eye' : 'eye-slash']" />
                                </button>

                                <ButtonWithIcon v-if="permissions.edit_book" :href="`/dashboard/books/edit/${book.id}`"
                                    background="primary" color="white" icon="pen-to-square" message="Editar livro" />
                            </div>
                        </div>

                        <img :src="`/storage/${book.cover_image_url}`" class="mb-4 book-cover mx-auto" alt="">

                        <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="data-tab" data-bs-toggle="tab"
                                    data-bs-target="#data-tab-pane" type="button" role="tab" aria-controls="data-tab-pane"
                                    aria-selected="true"><font-awesome-icon class="me-2" :icon="['fas', 'book']" />
                                    Dados</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="timeline-tab" data-bs-toggle="tab"
                                    data-bs-target="#timeline-tab-pane" type="button" role="tab"
                                    aria-controls="timeline-tab-pane" aria-selected="false"><font-awesome-icon class="me-2"
                                        :icon="['fas', 'list-check']" /> Linha do tempo</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                    data-bs-target="#movements-tab-pane" type="button" role="tab"
                                    aria-controls="movements-tab-pane" aria-selected="false"
                                    :disabled="movementRecords.length <= 0"><font-awesome-icon class="me-2"
                                        :icon="['fas', 'truck-moving']" /> Movimentações</button>
                            </li>
                        </ul>
                        
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active col-11 mx-auto py-3" id="data-tab-pane" role="tabpanel"
                                aria-labelledby="data-tab" tabindex="0">
                                <form action="">
                                    <fieldset disabled>
                                        <div class="row g-4 p-3">
                                            <div class="col-12">
                                                <label for="name" class="form-label">Nome do livro</label>
                                                <input type="text" id="name" autocomplete="on" class="form-control"
                                                    :value="book.name">
                                            </div>
                                            <div class="col-sm-3 col-8">
                                                <label for="isbn" class="form-label">ISBN</label>
                                                <input type="text" id="isbn" class="form-control" :value="book.isbn">
                                            </div>
                                            <div class="col-sm-3 col-4">
                                                <label for="total_pages" class="form-label">Páginas</label>
                                                <input type="text" id="total_pages" class="form-control"
                                                    :value="book.total_pages">
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <label for="original_price" class="form-label">Preço padrão</label>
                                                <input type="text" id="original_price" class="form-control"
                                                    :value="convertToCurrencyFormat(book.original_price)">
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <label for="quantity" class="form-label">Em estoque</label>
                                                <input type="text" id="quantity" class="form-control"
                                                    :value="`${book.quantity > 0 ? book.quantity : 'Em falta'}`">
                                            </div>
                                            <div class="col-12" v-if="book.description">
                                                <label for="description" class="form-label">Sinopse</label>
                                                <textarea class="form-control" id="description" :value="book.description"
                                                    rows="12"></textarea>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>

                                <template v-if="categories.length > 0">
                                    <hr>
                                    <h5 class="font-bold">Categorias vinculadas ao livro</h5>

                                    <div class="mt-3">
                                        <Link :href="`/dashboard/categories/${category.id}`"
                                            class="btn btn-warning rounded-pill mb-3 fs-12 font-bold text-dark d-inline-block me-2 p-2"
                                            v-for="(category, index) in categories" :key="index">
                                        {{ category.name }}
                                        </Link>
                                    </div>
                                </template>

                                <template v-if="authors.length > 0">
                                    <hr>
                                    <h5 class="font-bold">Autores vinculados ao livro</h5>

                                    <div class="row mb-3" id="author-book">
                                        <div class="col" v-for="(author, index) in authors" :key="index">
                                            <Link :href="`/autores/${author.clean_name}`">
                                            <img class="d-block mx-auto" :src="`/storage/${author.profile_picture_url}`"
                                                alt="" />
                                            <span class="text-center d-block mx-auto font-bold mt-2 fs-13">{{ author.name
                                            }}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </template>

                                <div class="row mb-3" v-if="book.presentation_youtube_url">
                                    <hr>
                                    <h5 class="font-bold mb-3">Vídeo de apresentação do livro</h5>
                                    <div class="col-11 mx-auto">
                                        <div class="mt-3 d-flex justify-content-center">
                                            <iframe class="rounded-4 youtube-video " :src="convertYouTubeLink(book.presentation_youtube_url)" title="YouTube video" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mb-3" v-if="book.spotify_link_url">
                                    <hr>
                                    <h5 class="font-bold mb-3">Audiobooks</h5>
                                    <a :href="book.spotify_link_url" target="_blank" class="btn btn-success font-bold fs-14">
                                        <font-awesome-icon class="me-2" :icon="['fab', 'spotify']" /> Ouvir no Sportify
                                    </a>
                                </div>

                            </div>

                            <div class="tab-pane fade" id="timeline-tab-pane" role="tabpanel" aria-labelledby="timeline-tab"
                                tabindex="0">
                                <div class="col-lg-12 overflow-auto activity-record my-4">
                                    <ActivityLogs title="Linha do tempo do livro" :activitys="activitys" />
                                </div>
                            </div>

                            <div class="tab-pane fade" id="movements-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                                tabindex="0">
                                <div class="col-lg-12 overflow-auto activity-record my-4">
                                    <RecordMovements :activitys="movementRecords" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>



