<script setup>
import { onMounted } from 'vue';
import { router } from '@inertiajs/vue3';
import AOS from 'aos';
import 'aos/dist/aos.css';

const props = defineProps({
    categories: Object,
    full: Boolean,
});

const goToThemePage = (slug) => router.get(`/temas/${slug}`);

onMounted(() => {
    AOS.init({
        once: true,
    });
});
</script>

<template>
    <div class="col-lg-10 col-11 mx-auto" id="themes-available">
        <h2 v-if="!full" class="text-center mb-4 title-with-arrows">Temas Disponíveis</h2>
        <div class="row d-flex justify-content-center">
            <div 
                v-for="(item, index) in full ? categories : categories.slice(0, 10)" 
                :key="index" 
                data-aos="zoom-in"
                data-aos-duration="1000"
                class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3"
            >
                <div class="card border d-flex p-3" @click="goToThemePage(item.clean_name)">
                    <img class="rounded-circle mx-auto border mt-2 shadow-sm" :src="`/storage/${item.photo}`" alt="">
                    <span class="mt-3 text-center fs-13 font-semibold">{{ item.name }}</span>
                </div>
            </div>
            <div 
                v-if="!full && categories.length > 10" 
                data-aos="zoom-in"
                data-aos-duration="1000"
                class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3 d-flex align-items-center justify-content-center"
            >
                <div class="card border d-flex p-3 all-themes w-100" @click="router.get('/temas')">
                    <font-awesome-icon class="mx-auto mt-2 text-white more-icon" :icon="['fas', 'circle-plus']" />
                    <span class="mt-3 text-center fs-13 font-semibold text-white">Ver Todos</span>
                </div>
            </div>
        </div>
    </div>
</template>
