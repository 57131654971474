<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const form = useForm({ 
    name: null, 
    display_name: null, 
    description: null 
});

const store = () => form.post('/dashboard/permissions');
</script>

<template>
    <Dashboard page_title="Criar permissão">
        <Breadcrumb currentPageName="Criar permissão" />
        <PageNavigationHeader pageTitle="Criar permissão" baseRoute="permissions" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border rounded-3">
                <div class="row">
                    <form class="col-10 mx-auto py-4" @submit.prevent="store">
                        <div class="row g-3">
                            <div class="col-md-4">
                                <label for="name" class="form-label">Nome da permissão</label>
                                <input type="text" autocomplete="off" class="form-control" id="name" name="name" placeholder="create-book"
                                    v-model="form.name" required>
                                <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                                }}</small>
                            </div>
                            <div class="col-md-8">
                                <label for="display_name" class="form-label">Nome legível da permissão</label>
                                <input type="text" class="form-control" id="display_name" display_name="display_name"
                                    placeholder="Criar livro" v-model="form.display_name" autocomplete="off" required>
                                <small class="text-danger mt-3" v-if="form.errors.display_name">{{ form.errors.display_name
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição da permissão</label>
                                <input type="text" class="form-control" id="description" description="description"
                                    placeholder="Essa permissão permite que o usuário crie um livro no sistema"
                                    v-model="form.description" autocomplete="off" required>
                                <small class="text-danger mt-3" v-if="form.errors.description">{{ form.errors.description
                                }}</small>
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit" :disabled="form.processing">Criar permissão</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

