<script setup>
import { ref } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Designs/Buttons/ButtonWithIcon.vue';

const props = defineProps({
    permission: Object,
    errors: Object
});

const formEditPermission = useForm({
    display_name: props.permission.display_name,
    description: props.permission.description,
});

//const permissionEditModalCloseButton = ref(null);

const update = () => router.put(`/dashboard/permissions/${props.permission.id}`, formEditPermission);
// const destroy = () => {
//     permissionEditModalCloseButton.value.click();
//     router.delete(`/dashboard/permissions/${props.permission.id}`);
// };
</script>

<template>
    <Dashboard :page_title="permission.display_name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb :currentPageName="permission.display_name" :linkList="[
                { previous_page_url: 'permissions', previous_page_name: 'Permissões' }
            ]" />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-6">
                        <h4 class="font-bold m-0">Editar dados da permissão</h4>
                    </div>
                    <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                        <ButtonWithIcon 
                            :href="`/dashboard/permissions/${permission.id}`" 
                            background="primary" 
                            color="white"
                            icon="eye" 
                            message="Visualizar" 
                            additionalStyles="me-2"
                        />
                    </div>
                </div>
                <hr>
                <form class="border-0 shadow-none" @submit.prevent="update">
                    <div class="row g-4">
                        <div class="col-sm-4">
                            <label for="display_name" class="form-label">Nome legível da permissão</label>
                            <input type="text" id="display_name" class="form-control"
                                v-model="formEditPermission.display_name" autocomplete="off">
                            <small class="text-danger" v-if="formEditPermission.errors.display_name">{{
                                formEditPermission.errors.display_name
                            }}</small>
                        </div>
                        <div class="col-sm-8">
                            <label for="description" class="form-label">Nome legível da permissão</label>
                            <input type="text" id="description" class="form-control"
                                v-model="formEditPermission.description" autocomplete="off">
                            <small class="text-danger" v-if="formEditPermission.errors.description">{{
                                formEditPermission.errors.description
                            }}</small>
                        </div>
                    </div>
                    <button class="btn btn-success mt-3 float-end" type="submit">
                        Atualizar dados
                    </button>
                </form>

                <!-- <ConfirmationModal modal_id="permissionDestroyModal" modal_title="Confirmação de remoção do cliente">
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir a permissão de {{ permission.display_name }}?
                    </template>
                    <template v-slot:modal-footer>
                        <button type="button" ref="permissionDestroyModalCloseButton" class="btn btn-secondary text-white"
                            data-bs-dismiss="modal">Voltar</button>
                        <button @click="destroy" type="button"
                            class="btn btn-danger text-white">Deletar permissão
                        </button>
                    </template>
                </ConfirmationModal> -->

                </div>
            </div>
        </div>
    </Dashboard>
</template>

