<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageNavigationHeader from '@/Pages/Dashboard/Shared/PageNavigationHeader.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Lear from './Components/Lear.vue';

import { vMaska } from "maska";
import { VMoney } from 'v-money';

import usePreviewImage from '@/Composables/PreviewImage.vue';
import useMoney from '@/Composables/Money.vue';
import usePriceFormatter from '@/Composables/PriceFormatter.vue';

const props = defineProps({
    authors: Object,
    categories: Object
});

const form = useForm({
    name: '',
    isbn: '',
    total_pages: '',
    original_price: '',
    quantity: '',
    visible: 1,
    cover_image_url: '',
    description: '',
    presentation_youtube_url: '',
    spotify_link_url: '',
    categories: [],
    authors: []
});

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();
const { money } = useMoney();
const { convertDecimalFormat } = usePriceFormatter();

const store = () => {
    form.transform((data) => ({
        ...data,
        quantity: (form.quantity == "" ? 0 : form.quantity),
        original_price: convertDecimalFormat(data.original_price),
    })).post('/dashboard/books');
};
</script>

<template>
    <Dashboard page_title="Criar livro">
        <Breadcrumb currentPageName="Criar livro" />
        <PageNavigationHeader pageTitle="Criar livro" baseRoute="books" />
        <div class="row mt-4 mx-0" id="book-create">
            <div class="bg-white border border-1 rounded-3">
                <form class="col-11 mx-auto p-3 mb-4 mt-3" @submit.prevent="store">
                    <div class="row g-3">
                        <div class="col-md-8">
                            <label for="name" class="form-label">Nome do livro</label>
                            <input type="text" class="form-control" autocomplete="off" id="name" name="name"
                                v-model="form.name" placeholder="5 ELEMENTOS DA FÉ" required />
                            <small class="text-danger mt-3" v-if="form.errors.name">{{ form.errors.name
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <label for="isbn" class="form-label">ISBN</label>
                            <input type="text" class="form-control" autocomplete="off" id="isbn" name="isbn"
                                v-model="form.isbn" placeholder="978-65-8539-813-8" v-maska data-maska="###-##-####-###-#"
                                required>
                            <small class="text-danger mt-3" v-if="form.errors.isbn">{{ form.errors.isbn
                            }}</small>
                        </div>
                        <div class="col-md-3 col-6">
                            <label for="total_pages" class="form-label">Total de páginas</label>
                            <input type="text" class="form-control" autocomplete="off" id="total_pages" name="total_pages"
                                v-model="form.total_pages" v-maska data-maska="#####" placeholder="80" required>
                            <small class="text-danger mt-3" v-if="form.errors.total_pages">{{
                                form.errors.total_pages
                            }}</small>
                        </div>
                        <div class="col-md-3 col-6">
                            <label for="original_price" class="form-label">Preço</label>
                            <div class="input-group">
                                <input type="text" autocapitalize="off" class="form-control" id="original_price"
                                    name="original_price" v-model.lazy="form.original_price" v-money="money" maxlength="7"
                                    placeholder="39.90" required>

                            </div>
                            <small class="text-danger mt-2" v-if="form.errors.original_price">{{
                                form.errors.original_price
                            }}</small>

                        </div>
                        <div class="col-md-3 col-6">
                            <label for="quantity" class="form-label">Quantidade</label>
                            <input type="text" autocomplete="off" class="form-control" id="quantity" name="quantity"
                                v-model="form.quantity" v-maska data-maska="####" placeholder="1">
                            <small class="text-danger mt-3" v-if="form.errors.quantity">{{
                                form.errors.quantity
                            }}</small>
                        </div>
                        <div class="col-md-3 col-6">
                            <label for="visible" class="form-label">Visível</label>
                            <select class="form-select" name="visible" id="visible" v-model="form.visible">
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="presentation_youtube_url" class="form-label">Link apresentação Youtube</label>
                            <input type="text" class="form-control" autocomplete="off" id="presentation_youtube_url" name="presentation_youtube_url"
                                v-model="form.presentation_youtube_url" placeholder="https://www.youtube.com/watch?verbodavida">
                            <small class="text-danger mt-3" v-if="form.errors.presentation_youtube_url">{{
                                form.errors.presentation_youtube_url
                            }}</small>
                        </div>
                        <div class="col-md-6">
                            <label for="spotify_link_url" class="form-label">Link Sportify</label>
                            <input type="text" class="form-control" autocomplete="off" id="spotify_link_url" name="spotify_link_url"
                                v-model="form.spotify_link_url" placeholder="https://open.spotify.com/intl-pt/album">
                            <small class="text-danger mt-3" v-if="form.errors.spotify_link_url">{{
                                form.errors.spotify_link_url
                            }}</small>
                        </div>
                        <div class="col-md-12">
                            <label for="description" class="form-label">Descrição</label> <small>(opcional)</small>
                            <textarea type="text" class="form-control" id="description"
                                placeholder="Forneça uma descrição minuciosa e abrangente do conteúdo do livro"
                                name="description" v-model="form.description" rows="5"></textarea>
                            <small class="text-danger mt-3" v-if="form.errors.description">{{
                                form.errors.description
                            }}</small>
                        </div>
                    </div>

                    <hr>

                    <div class="row g-3">
                        <div class="col-lg-6">
                            <h5 class="font-bold mb-3">Categorias</h5>
                            <ul class="list-group overflow-auto">
                                <li class="list-group-item p-3" v-for="(category, index) in categories" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="category.id"
                                        :id="'checkBoxCategory' + index" v-model="form.categories">
                                    <label class="form-check-label stretched-link ms-3" :for="'checkBoxCategory' + index"
                                        style="cursor: pointer;">{{
                                            category.name
                                        }}</label>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <h5 class="font-bold mb-3">Autores</h5>
                            <ul class="list-group overflow-auto">
                                <li class="list-group-item p-3" v-for="(author, index) in authors" :key="index">
                                    <input class="form-check-input me-1" type="checkbox" :value="author.id"
                                        :id="'checkBoxAuthor' + index" v-model="form.authors">
                                    <label class="form-check-label stretched-link ms-3" :for="'checkBoxAuthor' + index"
                                        style="cursor: pointer;">{{
                                            author.name
                                        }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr>

                    <div class="row g-3">
                        <div class="col-md-12">
                            <label for="formFile" class="form-label">Selecione uma foto para a capa do
                                livro - Tamanho máximo permitido: 2mb</label>
                            <input class="form-control" @input="form.cover_image_url = $event.target.files[0]" type="file"
                                id="formFile" accept="image/png,image/jpeg" @change="previewImage" required>
                            <small class="text-danger mt-3" v-if="form.errors.cover_image_url">{{
                                form.errors.cover_image_url
                            }}</small>
                        </div>
                        <div class="col-md-11 mx-auto">
                            <img class="d-block mx-auto preview-image" v-if="imageUrl" :src="imageUrl" />
                        </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col">
                                <a class="btn btn-info text-white fs-14 font-semibold" data-bs-toggle="offcanvas"
                                    href="#book-creation" role="button" aria-controls="book-creation">
                                    Ajuda <font-awesome-icon class="ms-2" :icon="['fas', 'circle-info']" />
                                </a>
                        </div>
                        <div class="col text-end">
                            <button class="btn btn-success" type="submit" :disabled="form.processing || imageSizeInMB > 2">
                                <span v-if="form.processing" class="spinner-border spinner-border-sm me-2" role="status"
                                    aria-hidden="true"></span>
                                Criar livro
                            </button>
                        </div>
                    </div>

                </form>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>

